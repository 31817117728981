import React from "react";
import { Row,Col,Input, AutoComplete, Card, Table, Typography, Button, Tooltip } from 'antd';
import {DownloadOutlined} from "@ant-design/icons"
import { JSONTOCSV,DownloadFile } from '../../../Settings/commonFunctions';
// import DataTable from 'react-data-table-component';
// Create Theme
export default function DataTable(props) {
    const [Search, setSearch] = React.useState(false)
    const [onDomFilter, setonDomFilter] = React.useState(props.propsTable.data)

    const handleSearchChange = e => {
        let searchString = e !== "" ? e : null
        if (searchString === null) {

            setonDomFilter(props.propsTable.data)
            return
        }
        let temp = props.propsTable.data.filter((obj) => {
            if (Object.values(obj).toString().toLowerCase().includes(searchString.toLowerCase())) return obj
        }
        )

        setonDomFilter(temp)
    }

    const handleDownload = ()=>{
        DownloadFile(JSONTOCSV(props.propsTable?.data),props.propsTable.downloadFileName);
    }

    let Pagination = {
        total:props.propsTable?.pagination?.total,
        showTotal:(total)=><Typography.Text className="is-center Tabletotal" >Total : {total}</Typography.Text>
    }
    if(props.propsTable?.pagination == false)Pagination = false
    


    React.useEffect(() => {
        setonDomFilter(props.propsTable.data)
        console.log("Table props :- ", props)
    }, [props])

    return (

        <Card
            {...props.propsCard}
            extra={
                <Row gutter={[10, 10]}>
                    <Col>
                        {props.propsCard.extra}
                    </Col>
                    {props.propsTable?.downloadFile == true && 
                        <Col>
                            <Tooltip title={`Download`}><Button type="primary" ghost icon={<DownloadOutlined />} onClick={handleDownload} /></Tooltip>
                        </Col>
                    }
                    <Col>
                        <AutoComplete
                            onChange={props.propsTable.onSearching ? props.propsTable.onSearching : handleSearchChange}
                                // style={{ width: 300 }}
                             >
                            <Input.Search placeholder="Search" />
                        </AutoComplete>
                        
                    </Col>
                    <Col>
                        {
                            props.propsTable.fundsHolding && <Button onClick={props.propsTable.handleCsvDownload} className="disableBTN" disabled={props.propsTable.CSVBtnloader || props.propsTable.loading} icon={<DownloadOutlined />}>{props.propsTable.CSVBtnloader || props.propsTable.loading ? "Downloading..." : "Download CSV"}</Button>
                        }
                    </Col>
                </Row>}
        >
            <Table
                size='small'
                {...props.propsTable}
                dataSource={onDomFilter}
                pagination={Pagination}
            />
        </Card>
    )
}

