import React from "react";
import DataFrame from "dataframe-js"
import { Collapse, Select, message, Upload, AutoComplete, Switch, Form } from 'antd';
import { SettingOutlined, InboxOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import SignalGeneration from "./SignalGenerationSettings"
import BasketCreation from "./BasketCreationSettings";
// import BasketCreation fro../../../../../../Users/kolli/OneDrive/Desktop/Mailing/BasketsCreationSettingsngs";

const stateTemplate = {
  user_uploaded_file_name :null,
  max_date:null,
  min_date:null,
  first_1000_rows_uploaded_file:[],
  user_uploaded_file: null,
  user_uploaded_headers: [],
  date_column: null,
  day_first: false,
  expanded: false,
  // signal generation checks Variables
  signal_generation: null,
  signal_generation_data:[]

}

export default function Main() {
  const [stateValues, setstateValues] = React.useState(stateTemplate);
  const [NumericColumns, setNumericColumns] = React.useState([]);
  const [expandIconPosition, setExpandIconPosition] = React.useState('left');
  const [SignalsGenerationSettingsData,setSignalsGenerationSettingsData] = React.useState(null)

  const props = {
    name: 'file',
    maxCount: 1,
    beforeUpload: () => false,
    onChange(filesObject) {
      if (filesObject.fileList.length === 0) {
        setstateValues(stateTemplate)
        return
      }
      uploadFile(filesObject.fileList[0])
    },
  };

  const uploadFile = (file) => {

    if ((file.size / 1024 / 1024) > 1000 && file.name.split(".")[1].toLowerCase() === "csv") {
      message.error(`File size too large ${parseFloat(file.size / 1024 / 1024).toFixed(2)} MB, Please use less than 1000MB file`)
      return
    }

    if (file.name.split(".")[1].toLowerCase() === "csv") {
      var obj_csv = {
        size: 0,
        dataFile: []
      };
      let reader = new FileReader();
      reader.readAsBinaryString(file.originFileObj);
      reader.onload = function (e) {
        obj_csv.size = e.total;
        obj_csv.dataFile = e.target.result
        handleStateChange(null, obj_csv.dataFile.split(/\r?\n/)[0].split(/,/), "user_uploaded_headers")
      }
      handleStateChange(null, file.originFileObj, "user_uploaded_file")
      handleStateChange(null, file.name, "user_uploaded_file_name")
    }
  }

  const GetMaxMinDates = () =>{
    DataFrame.fromCSV(stateValues.user_uploaded_file).then(df=>{
      let Numeric_columns = []
      let columns = df.listColumns()
      columns.map(x=>{
        let types = []
        let data = df.head(20).select(x).toArray()
        data.map(y=>{
          try{if(y[0]/1==y[0]){
            types.push("Number")
          }}
          catch(e){
            console.log(e);
          }          
        })
        if(types.length>0){
          let temp = types.map(y=>{
            if(y=="Number") return x
          })
          if(temp.length>0 && x != "security_id") Numeric_columns.push(x)
        }
      })
      setNumericColumns(Numeric_columns)


      df.sortBy(stateValues.date_column)
      let min_date = df.head(1).select(stateValues.date_column).toArray()[0][0]
      let max_date =df.tail(1).select(stateValues.date_column).toArray()[0][0]
      setstateValues({...stateValues, min_date, max_date})
    })
  }

  const handleStateChange = (e, v, element) => {
    if (element === 'expanded') {
      setstateValues(prevState => ({ ...prevState, [element]: stateValues['expanded'] === v ? false : v }));
      return
    }
    setstateValues(prevState => ({ ...prevState, [element]: v }));
  }

  const onChange = (key) => {
    if (key) handleStateChange(null, key, "expanded")
  };

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  React.useEffect(()=>{
    GetMaxMinDates()
  },[stateValues.date_column])

  return (
    <>
      <div>
        <Form.Item label="" valuePropName="checked">
          <Upload.Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for single and '.csv' upload only.
            </p>
          </Upload.Dragger>
        </Form.Item>

        {stateValues['user_uploaded_headers'].length !== 0 && <>
          <Form.Item label="Date Column" valuePropName="AutoCompleteChecked">
            <AutoComplete
              style={{ width: 300, }}
              allowClear
              // onSelect={onSelect}
              // onSearch={onSearch}
              placeholder="Select here"
              onChange={(v, e) =>{
                 v ? handleStateChange(e, v, 'date_column') : handleStateChange(e, null, 'date_column')
                 GetMaxMinDates(v)
                }}
              id="date_column"
            >{
                stateValues['user_uploaded_headers'].map((x, i) => <AutoComplete.Option key={i} value={x}>{x}</AutoComplete.Option>)
              }
            </AutoComplete>
          </Form.Item>
          <Form.Item label="Is 'date' the first part of the date format?" valuePropName="checked">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(v, e) => handleStateChange(e, v, 'day_first')}
              checked={stateValues['day_first']}
            />
          </Form.Item>
        </>
        }
      </div>
      <Collapse
        defaultActiveKey={['1']}
        activeKey={stateValues['expanded']}
        accordion={true}
        onChange={onChange}
        expandIconPosition={expandIconPosition}
      >
        <Collapse.Panel
            collapsible={(stateValues['date_column'] === null || !stateValues['user_uploaded_headers'].includes('security_id')) ? 'disabled' : ''}
            header={`Signal Generation Settings ${(stateValues['user_uploaded_file'] && !stateValues['user_uploaded_headers'].includes('security_id')) ? 'Security_id column missing from uploaded file please include the security_id column as well.' : ''}`}
            key="1" extra={genExtra()}
        >
        {stateValues['date_column'] !== null && stateValues['user_uploaded_file'] !== null &&  stateValues['user_uploaded_headers'].includes('security_id') &&
              <SignalGeneration
                handleStateChange={handleStateChange}
                NumericColumns={NumericColumns}
                stateValues={stateValues}
                setSignalsGenerationSettingsData={setSignalsGenerationSettingsData}
              />
            }
        </Collapse.Panel>

        <Collapse.Panel
          collapsible={(stateValues['date_column'] === null || !stateValues['expanded'] === "2" || SignalsGenerationSettingsData == null)? 'disabled' : ''}
          // collapsible={(stateValues['date_column'] === null)? 'disabled' : ''}
          // header={`${SignalsGenerationSettingsData?"Basket Creation settings" : "For Basket Creation settings Uploaded file please pass it through Signal Generation tab first"}`}
          header={"Basket Creation settings"}
          key="2" extra={genExtra()}>
        {SignalsGenerationSettingsData && 
          <BasketCreation 
            stateValues={stateValues}
            NumericColumns={NumericColumns}
            SignalsGenerationSettingsData = {SignalsGenerationSettingsData}
          />
        } 
        </Collapse.Panel>
      </Collapse>
    </>
  );
}
