// import {sortBy,includes} from 'lodash';

import moment from 'moment';
import {message} from 'antd'

export const getCookie = (name) => {
  if (!document.cookie) {
    return null;
  }
  const _cookie = document.cookie;
  const xsrfCookies = _cookie.split(';')
    .map(c => c.trim())
    .filter(c => c.startsWith(name + '='));
  if (xsrfCookies.length === 0) {
    return null;
  }
  return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

export const getGreetings = () => {
  let greet = 'Good '
  let date = new Date()
  let hours = date.getHours();
  if (hours < 12) return greet += 'Morning'
  if (hours < 16) return greet += 'Afternoon'
  if (hours < 24) return greet += 'Evening'
}

export const disableDates = (datestofilter, d) => {
  let temp = new Date(moment(d._d).format('YYYY-MM-DD')).getTime()
  return datestofilter.indexOf(temp) === -1 ? true : false
};

export const getBuySell = (input) => {
  if (input === 0) { return 'Neutral' }
  else if (input > 50) { return 'Strong Buy' }
  else if (input > 0) { return 'Buy' }
  else if (input >= -50) { return 'Sell' }
  else { return 'Strong Sell' }
}

export const fnum = (x) => {
  if (isNaN(x)) return x;

  if (x < 9999) {
    return x;
  }

  if (x < 1000000) {
    return Math.round(x / 1000) + "K";
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + "M";
  }

  if (x < 1000000000) {
    return Math.round((x / 1000000)) + "M";
  }

  if (x < 1000000000000) {
    return Math.round((x / 1000000000)) + "B";
  }

  return "1T+";
}

//var csv is the CSV file with headers
export const CSVJSON = csv => {
  var lines = csv.split("\n");
  var result = {};
  var headers = lines[0].split(",");
  for (let j = 0; j < headers.length; j++) {
      result[headers[j]] = [];
  }

  for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(',');
      for (let j = 0; j < headers.length; j++) {
          if (!result[headers[j]]) {
              result[headers[j]] = [];
          }
          const value = data[j];
          if (!isNaN(value)) {
              result[headers[j]].push(Number(value));
          } else {
              result[headers[j]].push(value);
          }
      }
  }
  return result; //JavaScript object
  // return JSON.stringify(result); //JSON
}

export const CSVTOJSON2 = csv =>{
  var lines = csv.split("\n");
  var result = [];
  var headers = lines[0].split(",");
  for (var i = 1; i < lines.length; i++) {
    if (lines[i] !== "") {
      var obj = {};
      // var currentline = lines[i].split(",");
      var currentline = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
    }
    result.push(obj);
  }
  return result;
}

export const abbrNum = (number, decPlaces, abbr) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);
  // Enumerate number abbreviations
  var abbrev = ["k", "m", 'B'];
  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);
    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      var numberr = Math.round(number * decPlaces / size) / decPlaces;
      // Handle special case where we round up to the next abbreviation
      if ((numberr === 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }
      // Add the letter for the abbreviation
      // number += abbrev[i];
      // We are done... stop
      break;
    }
  }
  if (abbr) {
    return number.toLocaleString();
  }
  return number.toLocaleString() + ' ' + abbrev[i];
}

export const Bnum = (x) => {
  if (isNaN(x)) return x;
  if (x >= 200000000000) return `Megacap`;
  else if (x < 200000000000 && x >= 10000000000) return `Largecap`;
  else if (x < 10000000000 && x >= 2000000000) return `Midcap`;
  else if (x < 2000000000) return `Smallcap`;
}

export const Anum = (x) => {
  if (isNaN(x)) return x;
  if (x >= 200000000000) return `B`;
  else if (x < 200000000000 && x >= 10000000000) return `B`;
  else if (x < 10000000000 && x >= 2000000000) return `B`;
  else if (x < 2000000000) return `B`;
}

export const Mnum = (x) => {
  if (isNaN(x)) return x;
  if (x >= 200000000000) return parseInt(Math.round((x / 1000000000)));
  else if (x < 200000000000 && x >= 10000000000) return parseInt(Math.round((x / 1000000000)));
  else if (x < 10000000000 && x >= 2000000000) return parseInt(Math.round((x / 1000000000)));
  else if (x < 2000000000) return parseInt(Math.round((x / 1000000000)));
}

export const getBuySellOnly = (input) => {
  if (input === 0) return 'Neutral'
  else if (input > 0) return 'Buy'
  else return 'Sell'
}

export const setColor = (xData) => {
  if (xData > 0) return '#43a047'
  else if (xData === 0) return '#787878'
  else if (xData < 0) return '#dd2c00'
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const JSONTOCSV = data => {
  const items = data
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(items[0])
  const csv = [ 
    header.join(','), // header row first
    ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n')
return csv
}

export const CSVTOFILE = (data, name, type = '.csv') => {
  window.URL = window.webkitURL || window.URL;
  var contentType = 'text/csv';
  var csvFile = new Blob([data], { type: contentType });
  var fileOfBlob = new File([csvFile], name + type);
  return fileOfBlob
}

export const DownloadFile = (file, name, type = '.csv') => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  let filetype = name.split('.')[1] !== ''
  link.setAttribute('download', filetype ? name + type : name); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const FileSizeCheck = (file,restric_size) => {
  const fileSizeLimit = parseInt(restric_size) * 1024 * 1024;

  if (file.file.size > fileSizeLimit) {
    message.error(`File size too large ${parseFloat(file.file.size / 1024 / 1024).toFixed(2)} MB, Please use less than or equal ${restric_size}MB file`);
    return false; // Prevent the file from being uploaded
  } 
  return true// Allow the file to be uploaded
};