import { Input, AutoComplete } from 'antd';
import { StockOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import axios from 'axios';

const renderTitle = (title) => (
    <span>
        {title}
        {/* <a
            style={{
                float: 'right',
            }}
            href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
        >
            more
        </a> */}
    </span>
);

const renderItem = (data, text, count) => ({
    data: data,
    value: text,
    label: (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {text}
            <span>
                <StockOutlined /> {count}
            </span>
        </div>
    ),
});

const constructOptions = data => {

    let tempOptions = [{
        label: renderTitle('Securities'),
        options: data.results.map(x => renderItem(x, x.composite_ticker + ' / ' + x.security_name, x.security_external_id))
    }]

    return tempOptions

}

export default function SecuritySelection(props) {
    const [options, setoptions] = useState([])
    const [loader, setloader] = useState(false)
    const [InpustData, setInpustData] = useState('')

    const getSecurityMasterData = text => {
        setoptions([])
        setloader(true)
        let k = encodeURIComponent(text);
        if (text.length > 0) {
            axios({
                method: 'get',
                url: `/securities/search/?security_type=${props?.security_type || ''}&limit=10&search=${k}`,
            })
                .then(res => {
                    setoptions(constructOptions(res.data))
                })
                .catch(err => {
                    setoptions([])
                })
                .finally(() => {
                    setloader(false)
                    // window.addEventListener('click', toggleSecmasterSearch, false)
                })
        } else {
            setloader(false)
            // setnoResults(false)
            // setlist(null)
        }
    }

    const handleSecurityIdSelector = (e,v) =>{
        if(props.getSecurityDetails){
            props.getSecurityDetails(v?.data); setInpustData(null);  
        }  
        else if(props.setSelectedID) props.setSelectedID(v?.data.id)
    }

    return (
        <AutoComplete
            allowClear
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            autoFocus={true}
            options={options}
            onChange={(v)=>{setInpustData(v)}}
            value={InpustData}
            onSearch={getSecurityMasterData}
            onSelect={(e, v) =>{ handleSecurityIdSelector(e,v) }}
            {...props}

        >
            <Input
                suffix={
                    <SearchOutlined />
                }
                style={{
                    width: window.location.pathname=="/altlab360"|| window.location.pathname=='/portfolio-creation'?'300px':'250px'
                }}
                loading={loader} 
                // size={props?.size ? props?.size : "medium"} 
                size={props?.size ? props?.size : "medium"} 
                placeholder={props?.placeholder ? props?.placeholder.toString() : window.location.pathname=="/altlab360" || window.location.pathname=='/portfolio-creation'?"Search for Securities to Add" : window.location.pathname=="/market" ? "Search": window.location.pathname=="/fundHoldings"||window.location.pathname=="/funds" ? "Select Ticker" : "Compare with MarketBenchmark"} />
        </AutoComplete>
    )

}
