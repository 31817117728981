import React from 'react'
import axios from 'axios'
import AWS from "aws-sdk"
import moment from 'moment'
import { Button, Switch,Checkbox, Form, Input, InputNumber, DatePicker, Select, Row, Col, Tooltip, Space, message, Modal } from 'antd';
import { PlusOutlined, MinusOutlined} from '@ant-design/icons';
export default function BasketCreation(props) {
    const [book_currencyValues, setbook_currencyValues] = React.useState([])
    const [category, setcategory] = React.useState([]);
    const [FactorLimits,setFactorLimits] = React.useState(false)
    const [TopBottom,setTopBottom] = React.useState(null)
    const [loader,setloader] = React.useState(false)
    const [FactorLimitsFromTo,setFactorLimitsFromTo] = React.useState([1])
    const [HTMLData,setHTMLData] = React.useState(null)


    const getSecurityCategories = () => {
        if (category.length > 0) return
        // setloader(true)
        axios({
            method: 'get',
            url: '/securities/details/securityCategories/',
        })
            .then(res => {
                setcategory(res.data)
            })
            .catch(err => {
                message.error(err.response.data.message)
                setcategory(null)
            }).finally(x => { 

             })
    };

    const GetHTMlData = (list)=>{
        let HTML = []
        list.map(x=>{
            HTML.push(
                <Col xs={24} >
                    <Row>
                        <Col xs={3} className='content-margin-1'>
                            <Form.Item
                                name={"from"+x}
                                label="From"
                                // rules={[{required: true}]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col xs={3}>
                            <Form.Item
                                name={"to"+x}
                                label="To"
                                // rules={[{required: true}]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                     </Row>
                 </Col>
                
                
                )
        

        })
        setHTMLData(HTML)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const HandleFactorFromToAdd = ()=>{
        let list =FactorLimitsFromTo
        let i = FactorLimitsFromTo[FactorLimitsFromTo.length-1]+1 
        list.push(i)
        setFactorLimitsFromTo(list)
        GetHTMlData(list)
        
    }
    const HandleFactorFromToRemove = ()=>{
        let list =FactorLimitsFromTo
        if (list.length>1){
            list.pop()
            setFactorLimitsFromTo(list)
            GetHTMlData(list)
        }
        
        
    }

    const getCurrency = () => {
        axios({
          method: 'post',
          url: '/securities/details/catalogue/',
        })
          .then(res => {
            setbook_currencyValues(res.data.currency)
            // setcountry(res.data.country)
            // setregionData(res.data.region)
            // setassetClass(res.data.assetClass)
          })
          .catch(err => {
            setbook_currencyValues([])
          })
    }

    const Upload_S3 = (data)=>{
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          });
        const S3 = new AWS.S3({params:{Bucket:data['s3_bucket']}});

        const params = {
            Bucket: data['s3_bucket'],
            Key: `${data['folder']}${props.stateValues.user_uploaded_file_name}`,
            Body: props.stateValues.user_uploaded_file
        }
        S3.putObject(params, (err, res) => {
            if (err) {
            console.log(err);
            } else {
                let Data = {
                    "payload_id":data['payload_id'],
                    "raw_files_storage_location":{"s3_bucket":data['s3_bucket'],"folder":data['folder']}
                }
                axios({
                    method: 'post',
                    url: "/baskets/BacktestScreener/StartDataProcessing/",
                    data: Data
                }).then((res)=>{
                    setloader(false)
                    message.success(res.data.message)
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 4000)
                }).catch(err => {
                    if(err.hasOwnProperty('response')){
                        message.error(err.response.data.message)
                    }
                }).finally(()=>{
                    setloader(false)
                })
                setloader(false)

            }})

    }

    const onFinish = (values) =>{
        let Data = {}
        setloader(true)
        let Factor_Limits_Data =  FactorLimitsFromTo.map(x=>{
                                                        return{
                                                            "topbottom":values["topbottom"]?values["topbottom"]:null,
                                                            "topbottomlimit":values["topbottomlimit"]?values["topbottomlimit"]:null,
                                                            "from": values["from"+x],
                                                            "to": values["to"+x],
                                                        }
                                                        
                                                    })
        let portfolio_settings = {}
        portfolio_settings['basket_name'] = values.BasketName
        portfolio_settings['book_currency'] = values.BookCurrency
        portfolio_settings['selection_churnout'] = values.selection_churnout
        portfolio_settings['link_universe_to_index_id'] = values.link_universe_to_index_id?values.link_universe_to_index_id:null
        portfolio_settings['rfolio_category'] = values.rfolio_category?values.rfolio_category:null
        portfolio_settings['factor_handling'] = values.factor_handling?values.factor_handling:null
        if (FactorLimits==true) portfolio_settings['factor_limits'] = Factor_Limits_Data


        Data['data_settings'] = props.SignalsGenerationSettingsData
        Data['portfolio_settings'] = portfolio_settings

        axios({
            method: 'post',
            url: "/baskets/BacktestScreener/",
            data: Data
        }).then((res)=>{
            Upload_S3(res.data)
        }).catch((err)=>{
            setloader(false)
            if(err.hasOwnProperty('response')){
                message.error(err.response.data.message)
            }
        })
    }
    
    React.useEffect(() => {
        getCurrency()
        getSecurityCategories()
        GetHTMlData(FactorLimitsFromTo)
        return () => {
        }
      },[])

    return (
    <Form
        name="basketCreation"
        // labelCol={{
        //   span: 4,
        // }}
        wrapperCol={{
          span: 4,
        }}
        layout="horizontal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={[10,10]}>
            {/* Basket Name */}
            <Col xs={24} className='content-margin-1'>
                <Form.Item
                label="Basket Name"
                name="BasketName"
                rules={[{ required: true, message: 'Please input basket name!', },]}
                >
                <Input />
                </Form.Item>
            </Col>
            {/* Book Currency */}
            <Col xs={24} className='content-margin-1'>
                {book_currencyValues.length !== 0 &&
                <Form.Item
                    label="Book Currency"
                    name="BookCurrency"
                    rules={[{ required: true, message: 'Please select currency!', },]}
                >
                    <Select
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    // onChange={handleChange}
                    >
                    {book_currencyValues.map(x => <Select.Option value={x.currency_code}>{x.currency_code}</Select.Option>)}
                    </Select>
                </Form.Item>}
            </Col>
            {/*Selection Churnout */}
            <Col xs={24} className='content-margin-1'>
                <Form.Item
                    label="Selection Churnout"
                    name="selection_churnout"
                    rules={[{ required: true, message: 'Please select selection churnout!', },]}
                >
                    <Select
                    // onChange={handleChange}
                    >
                        <Select.Option value={'rebalance'}>{'Rebalance'}</Select.Option>
                        <Select.Option value={'reallocate'}>{'Reallocate'}</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
            {/* Link Universe To Index Id*/}
            <Col xs={24} className='content-margin-1'>
                <Form.Item
                    name={'link_universe_to_index_id'}
                    label="Select Index">
                    <Select
                        // style={{ width: 300, }}
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                        allowClear
                        placeholder="Select here"
                    >{
                            category.length === 0 ? [] : category?.['indexconstituents'].map((x, i) => <Select.Option key={i} value={x.parent_security_id}>{x.security_name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
            {/* Rfolio Category */}
            <Col xs={24} className='content-margin-1'>
                <Form.Item
                label="Rfolio Category"
                name="rfolio_category"
                // rules={[{ required: true, message: 'Please select Rfolio Category!', },]}
                >
                <Select
                mode='multiple'
                allowClear={true}
                >
                    <Select.Option value={'long_only'}>{'Long Only'}</Select.Option>
                    <Select.Option value={'long_short'}>{'Long Short'}</Select.Option>
                    <Select.Option value={'short_only'}>{'Short Only'}</Select.Option>
                </Select>
                </Form.Item>
            </Col>
            {/* Factor Handling */}
            <Col xs={24} className='content-margin-1'>
                <Form.Item
                label="Factor Handling"
                name="factor_handling"
                // rules={[{ required: true, message: 'Please select Factor Handling!', },]}
                >
                <Select
                allowClear={true}
                mode='multiple'

                >
                    <Select.Option value={'equal_weights_lo'}>{'Equal Weights L/O'}</Select.Option>
                    <Select.Option value={'equal_weights_ls'}>{'Equal Weights L/S'}</Select.Option>
                </Select>
                </Form.Item>
            </Col>
            {/* Factor Limits */}
            <Col xs={24} className='content-margin-1'>
                <Form.Item 
                    name='factor_limits' 
                    label="Factor Limits"
                    >
                    <Switch
                        checked={FactorLimits}
                        onChange={(v) => setFactorLimits(v)} />
                </Form.Item>
            </Col>
            {FactorLimits &&<>
                <Col xs={24} className='content-margin-1'>
                    <Form.Item
                        label="TopBottom"
                        name="topbottom"
                        placeholder="Select here"
                        // rules={[{required:true,message: 'Please select TopBottom!', },]}
                    >
                        <Select
                            onChange={(v,e)=>{
                                setTopBottom(v)
                            }}
                            allowClear
                        >
                            <Select.Option key={0} value={"top"}>Top</Select.Option>
                            <Select.Option key={1} value={"bottom"}>Bottom</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                {["top","bottom"].includes(TopBottom) && <Col xs={24} className='content-margin-1'>
                    <Form.Item
                        label="TopBottomLimit"
                        name="topbottomlimit"
                        // rules={[{required: true}]}
                    >
                        <InputNumber min="0.00" step={"1"}/>
                    </Form.Item>
                </Col>}
                {HTMLData}
                <Col span={24} className='content-margin-1'>
                    <Row gutter={[10,10]}>
                        {/*Both are in same Line */}
                        <Col xs={3}>
                            <Form.Item
                                label="Add"
                            >
                                <Button style={{width:"60px",display:'block'}} title='Add more From and To options' ghost type='primary' icon={<PlusOutlined />} onClick={HandleFactorFromToAdd}></Button>
                            </Form.Item>
                        </Col>
                        <Col xs={3}>
                            <Form.Item
                                label="Remove"
                            >
                                <Button style={{width:"60px",display:'block'}} title='Remove From and To options'  ghost type='primary' icon={<MinusOutlined />} onClick={HandleFactorFromToRemove}></Button>
                            </Form.Item>
                        </Col>

                        {/* <Col xs={24}>
                            <Button style={{width:310,display:'block'}} title='Add more From and To options' ghost type='primary' icon={<PlusOutlined />} onClick={HandleFactorFromToAdd}></Button>
                        </Col>
                        <Col xs={24}>
                            <Button style={{width:310,display:'block'}} title='Remove From and To options'  ghost type='primary' icon={<MinusOutlined />} onClick={HandleFactorFromToRemove}></Button>
                        </Col> */}
                    </Row>
                </Col>
                </>
            }
            
            <Col xs={24} style={{margin:"0px 0px 10px 0"}}>
                <Button loading={loader} htmlType='submit'>Create Basket</Button>
            </Col>
        </Row>
      </Form >
  )
}
