import React from 'react'
import axios from 'axios'
import { Space, message, Table, Card, Row, Col, Button } from 'antd';
import { SaveOutlined} from '@ant-design/icons';
import { JSONTOCSV, DownloadFile } from '../../../Settings/commonFunctions'

export default function GetMappedTables(props) {

    const [activeTabKeyfortables, setActiveTabKeyfortables] = React.useState('1')
    const [keepMultiSelectiondata, setkeepMultiSelectiondata] = React.useState([]);
    const [selectedRowKeys, setselectedRowKeys] = React.useState([])



    const onSelectChange = (newSelectedRowKeys,records) => {
        setkeepMultiSelectiondata(records)
        setselectedRowKeys(newSelectedRowKeys)
    };

    const handleSaveMapping = () => {
        props.setloader(true)

        let temp1 = props.stateValues.allSecurityData?.['mapped_securities']
        let temp2 = []
        temp1 = [...temp1, ...temp2]
        temp1 = JSON.stringify(temp1)
        const Data = new FormData()
        // Data.append('input_file', props.fileObject)
        Data.append('initial_mapping', false)

        Data.append('security_mapping_file', props.stateValues['user_uploaded_file'])
        Data.append('date_column', props.stateValues['date_column'])

        Data.append('security_mapped', temp1)
        Data.append('keep_multi_security_mapped', JSON.stringify(keepMultiSelectiondata))
        Data.append('multi_security_mapped', JSON.stringify(props.stateValues.allSecurityData?.['multi_mapped_securities']))
        Data.append("day_first", props.stateValues['day_first'])
        Data.append('security_unmapped', JSON.stringify(props.stateValues.allSecurityData?.['unmapped_securities']))

        let stop = false
        if(props.stateValues.BrandmappingColumn == null) {
            message.error('Please Select Brand Mapping Column');
            props.loader(false);
            stop=true
            return
        }
        if(props.stateValues.brandType.length == 0 ) {
            message.error('Please Select Brand Type');
            props.loader(false);
            stop=true
            return
        }

        Data.append('brand_name',props.stateValues.BrandmappingColumn)
        Data.append('brand_type',props.stateValues.brandType)
        if (stop == true){props.loader(false); return}

        Data.append('input_changes', props.stateValues.InputChanges)
        Data.append('input_replace_file', props.stateValues.InputReplaceFile)
        Data.append('point_in_time',props.stateValues.point_in_time)

        let url = ''
        {url="/securities/details/BrandMapping/"}

        axios({
            method: 'post',
            url: url,
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: Data
        }).then(res => {
            let mappedData = res.data['mapped_securities'].map((x)=>{
                return{
                    "Data":x.data_name,
                    "Company":x.company,
                    "Company Type":x.company_type,
                    "Company Symbol":x.company_symbol,
                    "Parent Company":x.parent,
                    "Company Type":x.parent_type,
                    "Symbol":x.parent_symbol,
                }
            })
            let multimappedData = res.data?.multi_mapped_securities?.map((x)=>{
                return{
                    "Data":x[props.stateValues.BrandmappingColumn],
                    "Parent Company":x.parent_name,
                    "Company Type":x.company_type,
                    "Symbol":x.symbol,
                    "Accuracy":x.accuracy,
                    "Country":x.country_name,
                    "Currency":x.currency_code,
                    "Figi Ticker":x.figi_ticker,
                    "Composite Ticker":x.composite_ticker
                }})
            props.handleStateChange(null,mappedData,'mappedData')    
            props.handleStateChange(null,multimappedData,'multimappedData')
            // props.setallSecurityData({ ...props.stateValues.allSecurityData, ...res['data'] })
            props.handleStateChange(null,res.data,'allSecurityData')
            props.handleStateChange(null,res.data['mapped_count'],'mappedcount')
            props.handleStateChange(null,res.data['unmapped_securities'].length,'unmappedcount')
            props.handleStateChange(null,res.data['multimap_count'],'multimappedcount')
            // props.setmappedcount(res.data['mapped_securities'].length)
            // props.setunmappedcount(res.data['unmapped_securities'].length)
            // props.setmultimappedcount(res.data['multimap_count'])
        }).catch(err => {
            // props.setallSecurityData(null)
            props.handleStateChange(null,null,'allSecurityData')
            err.response?.data?.message ? message.error(`${err.response?.data?.message}`, 2) : err.response?.data?.detail ?message.error(`${err.response?.data?.detail}`, 2): message.error('There Is An Internal Issue While Mapping Selected Securities', 2);

        }).finally(() => {
            props.setloader(false)
            setkeepMultiSelectiondata([])
            setselectedRowKeys([])

        })


    }


    return(
        props.stateValues.allSecurityData && 
        <Col span={24}>
            <Card
                className='Glass'
                tabList={[
                        { key:'1',tab: 'Successfully Linked'},
                        { key:'2', tab:"Unable to Find"},
                        { key:'3', tab:"To be Reviewed"}
                    ]
                }
                activeTabKey={activeTabKeyfortables}
                onTabChange={(v)=>setActiveTabKeyfortables(v)}
                tabBarExtraContent={
                    <Row gutter={10}>
                        {activeTabKeyfortables == 3 && props.stateValues.allSecurityData && keepMultiSelectiondata.length !== 0 &&
                            // <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} className='content-margin-1'>
                                <Space>
                                    <Button
                                        variant="outlined"
                                        ghost
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        loading={props.loader}
                                        component="span"
                                        onClick={handleSaveMapping}>Save Mappings</Button>
                                    {/* {Location.pathname == "/SignalGeneration" && <Button variant="outlined" color="primary" onClick={handleNext}>Signal Generation</Button>} */}
                                    {/* {Location.pathname == "/Tickerization" && <Button variant="outlined" color="primary" onClick={()=>setvisible(true)}>Upload to S3</Button>} */}
                                </Space>
                            // </Col>
                        }
                        {props.stateValues.allSecurityData !== null && props.stateValues.allSecurityData?.mapped_securities.length>0 && activeTabKeyfortables == 1 &&
                            <Col xs={24} >
                                <Button
                                    // style=
                                    variant="outlined"
                                    ghost
                                    type="primary"
                                    // onClick={() => DownloadFile(JSONTOCSV(props.stateValues.allSecurityData?.mapped_securities), `Mapped Brands`)}>
                                    onClick={() => DownloadFile(JSONTOCSV(props.stateValues?.mappedData), `Successfully Linked`)}>
                                    Download</Button>
                            </Col>
                        }
                        {props.stateValues.allSecurityData !== null && props.stateValues.allSecurityData?.unmapped_securities.length > 0 && activeTabKeyfortables == 2 &&
                            <Col xs={24} >
                                <Button
                                    // style=
                                    variant="outlined"
                                    ghost
                                    type="primary"
                                    onClick={() => DownloadFile(JSONTOCSV(props.stateValues.allSecurityData?.unmapped_securities), 'Unable To Find')}>
                                    Download</Button>
                            </Col>
                        }
                        {props.stateValues.allSecurityData !== null && props.stateValues.allSecurityData?.multi_mapped_securities?.length>0  && activeTabKeyfortables == 3 &&
                            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} style={{paddingRight:'10px'}} >
                                <Button
                                    // style=
                                    variant="outlined"
                                    ghost
                                    type="primary"
                                    onClick={() => DownloadFile(JSONTOCSV(props.stateValues?.multimappedData), `Conflicts To Resolve` )}>
                                    Download</Button>
                            </Col>
                        }
                    
                    </Row>
                }
            >
            { activeTabKeyfortables =='1' && props.stateValues.allSecurityData &&
            <>
                <Table
                    loading={props.loader}
                    columns={props.stateValues.Act_Map_columns}
                    dataSource={
                        props.stateValues.allSecurityData['mapped_securities'].map(row => {
                            return{

                                'brand_name': row.data,
                                'company':row.company,
                                'company_symbol':row.symbol,
                                'company_type':row.company_type,
                                'parent':row.parent||row.parent_name,
                                'parent_type':row.parent_type,
                                'parent_symbol':row.parent_symbol,
                                'symbol':row.symbol,
                                'security_id':row.id,
                                'accuracy':row.accuracy,
                                'valid_from':row?.valid_from?row.valid_from:"",
                                'valid_to':row?.valid_to?row.valid_to:"",
                                'isin':row.isin,
                                'mic':row.mic
                            }
                })}
                />
                {/* {props.allSecurityData !== null && <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => DownloadFile(props.allSecurityData?.['securities_mapped_data'], 'Input Data After Securities Mapped')}>
                    Download Data With Securities Mapped</Button>} */}
            </>
            }
            { activeTabKeyfortables =='2' && props.stateValues.allSecurityData &&
                <Table
                    loading={props.loader}
                    columns={props.stateValues.Act_Map_columns}
                    dataSource={
                        props.stateValues.allSecurityData['unmapped_securities'].map(row => {
                            return{
                                'brand_name': row.data,
                                'parent_name':row.parent_name,
                                'company_type':row.company_type,
                                'symbol':row.symbol,
                                'security_id':row.security_id,
                                'accuracy':row.accuracy,
                                'isin':row.isin,
                                'mic':row.mic
                            }
                    })}
                /> 
            }
            {activeTabKeyfortables =='3' && props.stateValues.allSecurityData &&
                <Table
                    loading={props.loader}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                    // rowSelection={true}
                    rowKey={(record) => record.key}
                    columns={props.stateValues.Act_Map_columns}
                    dataSource={
                        props.stateValues.allSecurityData?.multi_mapped_securities?.map((row,i)=>{
                            return{
                                'key':i,
                                'brand_name': row[props.stateValues.BrandmappingColumn],
                                'parent_name':row.parent_name,
                                'company_type':row.company_type,
                                'symbol':row.symbol,
                                'valid_from':row?.valid_from?row.valid_from:"",
                                'valid_to':row?.valid_to?row.valid_to:"",
                                'security_id':row.id,
                                'country_name':row.country_name,
                                'currency_code':row.currency_code,
                                'accuracy':row.accuracy,
                                'figi_ticker':row.figi_ticker,
                                'composite_ticker':row.composite_ticker,
                                'isin':row.isin,
                                'mic':row.mic
                            }
                        })
                    }
                />
            }
            {/* {activeTabKeyfortables ===3 && props.allSecurityData && keepMultiSelectiondata.length !== 0 &&
            <Col xs={24} className='content-margin-1'>
                <Space>
                    <Button
                        variant="outlined"
                        color="primary"
                        loading={Loader2}
                        component="span"
                        onClick={handleSaveMapping}>Save Mappings</Button>
                </Space>
            </Col>} */}
            </Card>
        </Col>
    )

}