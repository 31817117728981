import React from 'react';
import axios from 'axios';
import { Avatar, Layout, Badge, PageHeader, Tag, Button, Space, Typography, Menu, Dropdown, message, Tooltip, Drawer, Row, Col } from 'antd';
import { UserOutlined, BellOutlined, LogoutOutlined, SwitcherFilled } from '@ant-design/icons';
import Intercom from '@intercom/messenger-js-sdk';
// import Invisage_Symbol_RGB_Yellow from "../../../../assets/images/Invisage_Symbol_RGB_Yellow.png"
import Invisage_Symbol_RGB_Yellow from "../../../../assets/images/althubLogo.png"
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from '../../../Settings/useAuth';
import Profile from '../ProfileBar/Profile';
import Notification from './notification';

export default function Main(props) {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { logout } = useAuth()
  const [Profilevisible, setProfilevisible] = React.useState(false)
  const [Notificationvisible, setNotificationvisible] = React.useState(false)
  const [details, setDetails] = React.useState({})

  const removeCookies = () => {
    // Get all cookies as an array
    const cookies = document.cookie.split(";");
  
    // Iterate through each cookie
    for (let cookie of cookies) {
      // Get the cookie name
      const cookieName = cookie.split("=")[0].trim();
  
      // Set the cookie expiration date to a past date to delete it
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  };
  
  // Call this function during logout
  

  const LogMeOut = () => {
    localStorage.clear();
    logout()
    // location.reload()
    axios({
      method: 'post',
      url: '/users/logout/',
    })
      .then(res => { })
      .catch(err => { })
      .finally(() => {
        localStorage.clear();
        removeCookies();
        if (window.Intercom) {
          window.Intercom('shutdown');
        }
        window.location.reload(true)
      })
  }

  const handleMenuClick = e => {
    switch (e.key) {
      case "1":
        setProfilevisible(true)
        break;
      case "2":
        LogMeOut()
        break;

      default:
        break;
    }

  }

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }


  const items = [{name:"Logout",icon:<LogoutOutlined/>}].map((x,i)=>getItem(x.name,i+2,x.icon))

  const getUserData = () =>{
    axios({
      method: 'GET',
      url: `/users/?id=${props.login.user.id}`,  
    }).then(res => {
      setDetails(res.data.results[0])
    }).catch(err => {
      message.error(err.response.data.data)
    }).finally(() => {
    })
  };

  // React.useEffect(()=>{
  //   if (props?.login?.user?.id)getUserData()
  // },[props])


  return <PageHeader
    // title={!props.login && <img src={Invisage_Symbol_RGB_Yellow} alt="logo" className='padding-left-1' width='200px' height='60px' />}
    className="site-layout-background"
    subTitle={
      !props.login ? <Typography.Title style={{ marginBottom: '0rem' }}></Typography.Title> :
        <Row>
          <Col>
            <div id='Providers-list-slot-1'></div>
            <div id='Basket-list-slot-1'></div>
          </Col>
          <Col>
            <div id='Providers-list-slot-2'></div>
            <div id='Basket-list-slot-2'></div>
          </Col>
        </Row>
    }
    // tags={<Tag color="blue">Running</Tag>}
    extra={[
      <Space size={25}>
        {
          props.login ? <>
            {/* <Badge count={0}>
              <Button onClick={() => setNotificationvisible(true)} type="dashed" shape="circle" icon={<BellOutlined />} />
            </Badge> */}
            {/* <Dropdown
              overlay={<Menu
                theme="dark"
                onClick={handleMenuClick}
                mode="inline"
                items={items}
              />}
              placement="bottomLeft" arrow>
              <Avatar
                size={30}
                // src='https://avatars1.githubusercontent.com/u/8186664?s=460&v=4'
                src={details.profile_picture_url?details.profile_picture_url:'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4'}
                icon={<UserOutlined />} />
              <LogoutOutlined />
            </Dropdown> */}
            { window.location.pathname !== '/TrySymlink' && (<Tooltip title='Logout'><Button type='text' ghost  onClick={()=>LogMeOut()}> <LogoutOutlined style={{fontSize:'24px'}}/></Button></Tooltip>)}
          </>
            : null
          // <Button type="dashed" onClick={() => Navigate('/providers', { replace: true })} icon={<BellOutlined />}>Providers</Button>
        }
      </Space>

    ]}

  // avatar={{ }}
  // breadcrumb={ "routes" }
  // {/* <DropdownMenu key="more" />, */}
  >
    {/* {props.login &&
      <Drawer
        destroyOnClose={true}
        id='for-profile'
        className='is-capitalized'
        title={"Howdy " + props.login?.user?.first_name}
        placement={"right"}
        closable
        onClose={() => setProfilevisible(false)}
        open={Profilevisible}
        key={"right-Profile"}
      >
        <Profile login={props.login} details={details} />
      </Drawer>}
    <Drawer
      destroyOnClose={true}
      id='for-notification'
      className='is-capitalized'
      title={"Notifications"}
      placement={"right"}
      closable
      onClose={() => setNotificationvisible(false)}
      open={Notificationvisible}
      key={"right-Notifications"}
    >
      <Notification Open={Notificationvisible} setNotificationvisible={setNotificationvisible} />
    </Drawer> */}

  </PageHeader>


}

