import React from 'react'
import moment from 'moment'
import { Select, Switch, Form, Row, Col, Button, DatePicker, Input, Tooltip,} from 'antd';

export default function SignalGeneration(props) {
    const template = {
        aggregate_data: [],
        does_data_contain_actionable_signal:false,
        aggregate_data_upto_ticker: false,
        add_complemantry_market_data: false,
        use_fields_in_model: [],
        out_of_sample_status: false,
        train_sample_range: props.stateValues?.['min_date'] ? moment(props.stateValues?.['min_date']).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        test_sample_range: props.stateValues?.['min_date'] ? moment(props.stateValues?.['min_date']).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),

        train_test_selection_input: props.stateValues?.['min_date'] ? moment(props.stateValues?.['min_date']).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),

        data_frequency: [],
        signal_frequency: [],
        target_date_type: [],
        target_date_value: 1,

        file_active_delay: 0,

        data_column: [],

        link_to_index: null,
    }

    const timer_SignalGeneration = React.useRef();
    const [Datafrequency, setDataFrequency] = React.useState("daily");
    const [dropdownValues, setdropdownValues] = React.useState(template);
    const [aggregateDataSelectedGroup, setaggregateDataSelectedGroup] = React.useState([]);
    const [SignalType, setSignalType] = React.useState(null);
    const [aggregateDatafilterValues, setaggregateDatafilterValues] = React.useState([]); // used for filter the seleced aggregate data from aggregate_data_options
    const [aggregate_data_options, setaggregate_data_options] = React.useState([]);

    const [choose_signal_change_over, setchoose_signal_change_over] = React.useState("direct_value")




        
    const GetSignalDataInfo = () => {
        let tempData = []
        props.stateValues['user_uploaded_headers']?.forEach(x => {
            if (!['internal_security_name', 'internal_symbol', 'security_id',props.stateValues['date_column']].includes(x)) {
                let aggdata= ['max', 'min', 'sum', 'count', 'mean']
                tempData.push({[x]:aggdata.map(y=>x+'-'+y)})
            }
        })
        setaggregate_data_options(tempData)
    }


    const handleDropDownChanges = (e, v, element) => {
        
        if (element === 'data_frequency' || element === 'signal_frequency') {
            let decide = v !== document.getElementById(element === "signal_frequency" ? "data_frequency" : 'signal_frequency').value
            setdropdownValues(prevState => ({
                ...prevState,
                [element]: v,
                aggregate_data_upto_ticker: decide
            }));
            return
        }
        if (element === 'aggregate_data') {
            // const disable = ()=>{
            //     console.log(v[0].split("-")[0]);
            //     const element1 = document.getElementById(v[0])
            //     console.log(element1);
            // }
            let temp = []
            let SelectedGroup = [] //
            if (v.length > 0) v.forEach(x => {
                temp.push({ [x.split('-')[0]]: x.split('-')[1] });
                if(!SelectedGroup.includes(x.split('-')[0])){SelectedGroup.push(x.split('-')[0])} //pushing all selected aggregate data group names to SelectedGroup
            })
            setaggregateDataSelectedGroup(SelectedGroup) // storing all selected group names in aggregateDataSelectedGroup
            setaggregateDatafilterValues(v) // "v" means selected aggregate data and storing in aggregateDatafilterValues
            setdropdownValues(prevState => ({ ...prevState, [element]: temp }));
            return
        }

        if ((element === 'aggregate_data') && v.length !== 0) { document.getElementById(element).setCustomValidity(''); document.getElementById(element).reportValidity(); }
        else if ((element === 'aggregate_data') && v.length === 0) { document.getElementById(element).setCustomValidity('Please fill this field'); document.getElementById(element).reportValidity(); }

        if ((element === 'use_fields_in_model') && v.length !== 0) { document.getElementById(element).setCustomValidity(''); document.getElementById(element).reportValidity(); }
        else if ((element === 'use_fields_in_model') && v.length === 0) { document.getElementById(element).setCustomValidity('Please fill this field'); document.getElementById(element).reportValidity(); }
        setdropdownValues(prevState => ({ ...prevState, [element]: v }));
    }

    const submitForm = (e) => {
        props.handleStateChange(null, 2, "expanded")
        const Data = {}
        Data['date_column'] = props.stateValues['date_column']
        Data['day_first'] = props.stateValues['day_first']
        Data['data_frequency'] = e['data_frequency']
        Data['signal_frequency'] = e['signal_frequency']
        Data['aggregate_data_up_to_ticker'] = dropdownValues['aggregate_data_upto_ticker']
        // Data['columns_aggregation_types'] = JSON.stringify(dropdownValues['aggregate_data'])
        if (dropdownValues['aggregate_data_upto_ticker']==true) Data['columns_aggregation_types'] = dropdownValues['aggregate_data']
        Data['signal_type'] = e['signal_type']
        if (e['signal_type']=="derieved_from_data"){
            Data['choose_data_columns'] = e['choose_data_columns']
            Data['choose_signal'] = e['choose_signal']
            if(["percentage_change","change"].includes(e['choose_signal']))Data['change_over'] = e['change_over']
        }
        if (e['signal_type'] == "modeled_using_ml"){
            Data['add_complemantry_market_data'] = dropdownValues['add_complemantry_market_data']
            Data['out_of_sample_status'] = dropdownValues['out_of_sample_status']
            Data['does_data_contain_actionable_signal'] = dropdownValues['does_data_contain_actionable_signal']
            Data['use_fields_in_model'] = e['use_fields_in_model']

            if(dropdownValues['out_of_sample_status']== true){
                Data['train_model_until'] = moment(e['train_sample_range']).format('YYYY-MM-DD')
                Data['test_model_untill'] = moment(e['test_sample_range']).format('YYYY-MM-DD')
            }
            else{
                Data['train_model_until'] = moment(e['train_test_selection_input']).format('YYYY-MM-DD')
            }
        }
        Data['notify_results_at'] = e['notify_results_at']
        props.setSignalsGenerationSettingsData(Data)

    }


    React.useEffect(() => {
        // effect
        GetSignalDataInfo()
        return () => {
            // cleanup
            clearTimeout(timer_SignalGeneration.current);
            // setsignalGenerationCheck(null)
        }
    }, [])
    
    return (
            <>
                <Form className='' onFinish={submitForm}>
                    <Row>
                        {/*Data Frequency*/}
                        <Col xs={24} className='content-margin-1'>
                            <Form.Item
                                name={'data_frequency'}
                                rules={[{
                                    required: true,
                                    message: 'Please select data frequency!',
                                }]}
                                label="Data Frequency">
                                <Select
                                    style={{ width: 300, }}
                                    allowClear
                                    onChange={(v, e) => {
                                        v ? handleDropDownChanges(e, v, 'data_frequency') : handleDropDownChanges(e, null, 'data_frequency')
                                        setDataFrequency(v);
                                    }}
                                    placeholder="Select here"
                                    id="data_frequency"
                                >{
                                        ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*Signal Frequency*/}
                        <Col xs={24} className='content-margin-1'>
                            <Form.Item
                                name={'signal_frequency'}
                                rules={[{
                                    required: true,
                                    message: 'Please select signal frequency!',
                                }]}
                                label="Signal Frequency">
                                <Select
                                    style={{ width: 300, }}
                                    allowClear
                                    mode='multiple'
                                    onChange={(v, e) => v ? handleDropDownChanges(e, v, 'signal_frequency') : handleDropDownChanges(e, null, 'signal_frequency')}
                                    placeholder="Select here"
                                    id="signal_frequency"
                                >
                                    {Datafrequency=="daily"&&
                                        ['daily','weekly', 'monthly', 'quarterly', 'yearly'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                    }
                                    {Datafrequency=="weekly"&&
                                        ['weekly', 'monthly', 'quarterly', 'yearly'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                    }
                                    {Datafrequency=="monthly"&&
                                        ['monthly', 'quarterly', 'yearly'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                    }
                                    {Datafrequency=="quarterly"&&
                                        ['quarterly', 'yearly'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                    }
                                    {Datafrequency=="yearly" &&
                                        ['yearly'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*Aggrigation*/}                        
                        <Col xs={24} className='content-margin-1'>
                            <Row>
                                <Col xs={24} className=''>
                                    <Form.Item name='aggregate_data_upto_ticker' label="Aggregate Data Upto Parent Listed Company">
                                        <Switch
                                            checked={dropdownValues['aggregate_data_upto_ticker']}
                                            onChange={(v, e) => handleDropDownChanges(e, v, 'aggregate_data_upto_ticker')} />
                                    </Form.Item>
                                </Col>
                                {dropdownValues['aggregate_data_upto_ticker'] && <>
                                    <Col xs={24} style={{ marginTop: '-1rem', marginLeft: '1rem' }}>
                                        <Form.Item
                                            name={'aggregate_data'}
                                            rules={[{
                                                required: true,
                                                message: 'Please select aggregate data!',
                                            }]}
                                            label="Aggregate Data">
                                            <Select
                                                style={{ width: 300, }}
                                                allowClear
                                                mode='tags'
                                                placeholder="Select here"
                                                onChange={(v, e) => v ? handleDropDownChanges(e, v, 'aggregate_data') : handleDropDownChanges(e, null, 'aggregate_data')}
                                                id="aggregate_data"
                                            > 
                                            {
                                                aggregate_data_options?.map((x) => (
                                                    <Select.OptGroup label={Object.keys(x)[0]}>
                                                      {x[Object.keys(x)[0]].filter((o) => !aggregateDatafilterValues.includes(o)).map((y) => (
                                                        <Select.Option value={y} disabled={ aggregateDataSelectedGroup.includes(`${Object.keys(x)[0]}`)? true : false }>{y} </Select.Option>
                                                      ))}
                                                    </Select.OptGroup>
                                                  ))
                                            }
                                            </Select>
                                        </Form.Item>
                                    </Col> 
                                </>
                                }
                            </Row>
                        </Col>
                        {/*Signal Type*/}
                        <Col xs={24} className='content-margin-1'>
                            <Form.Item
                                    name={'signal_type'}
                                    rules={[{
                                        required: true,
                                        message: 'Please select signal type!',
                                    }]}
                                    label="Signal Type">
                                    <Select
                                        style={{ width: 300, }}
                                        allowClear
                                        placeholder="Select here"
                                        onChange={(v,e)=>{
                                            setSignalType(v)
                                            // v ? handleDropDownChanges(e, v, 'signal_type') : handleDropDownChanges(e, null, 'signal_type')
                                        }}
                                        id="signal_type"
                                    >{
                                            ["derieved_from_data","modeled_using_ml"].map((x, i) => <Select.Option className='is-capitalized' key={i} value={x}>{x.split('_').join(" ")}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                        </Col>
                        {/* Derived from Data */}
                        {SignalType == "derieved_from_data" && <>
                            <Col xs={24} className='content-margin-1'>
                                <Form.Item
                                    name={'choose_data_columns'}
                                    rules={[{
                                        required: true,
                                        message: 'Please select signal type!',
                                    }]}
                                    label="Choose Numeric Data Columns">
                                    <Select
                                        style={{ width: 300, }}
                                        allowClear
                                        mode='multiple'
                                        placeholder="Select here"
                                        onChange={(v,e)=>{

                                        }}
                                        id="choose_data_columns"
                                    >{
                                            props.NumericColumns.map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} className='content-margin-1'>
                                <Form.Item
                                    name={'choose_signal'}
                                    rules={[{
                                        required: true,
                                        message: 'Please select signal type!',
                                    }]}
                                    label="Choose Signal">
                                    <Select
                                        style={{ width: 300, }}
                                        allowClear
                                        placeholder="Select here"
                                        onChange={(v,e)=>{
                                            setchoose_signal_change_over(v)
                                        }}
                                        id="choose_signal"
                                    >{
                                            ["direct_value" ,"percentage_change","change"].map((x, i) => <Select.Option className='is-capitalized' key={i} value={x}>{x.split('_').join(" ")}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            {choose_signal_change_over != "direct_value" &&
                            <Col xs={24} className='content-margin-1'>
                                <Form.Item
                                    name={'change_over'}
                                    rules={[{
                                        required: true,
                                        message: 'Please select signal type!',
                                    }]}
                                    label="Change Over">
                                    <Select
                                        mode='multiple'
                                        style={{ width: 300, }}
                                        allowClear
                                        placeholder="Select here"
                                        onChange={(v,e)=>{
                                            setchoose_signal_change_over(e)
                                        }}
                                        id="change_over"
                                    >{
                                        ['last_period_value','same_period_last_year','average_12_months'].map((x, i) => <Select.Option className='is-capitalized' key={i} value={x}>{x.split('_').join(" ")}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            }
                            </>
                        }
                        {/* ML Model */}
                        {SignalType == "modeled_using_ml" && <>
                            {/*Use ferilds in ML Model */}
                            <Col xs={24} className='content-margin-1'>
                                <Form.Item
                                    name={'use_fields_in_model'}
                                    rules={[{
                                        required: true,
                                        message: 'Please select fields in model!',
                                    }]}
                                    label="Use Fields to Train ML Model">
                                    <Select
                                        style={{ width: 300, }}
                                        allowClear
                                        mode='multiple'
                                        placeholder="Select here"
                                        onChange={(v, e) => v ? handleDropDownChanges(e, v, 'use_fields_in_model') : handleDropDownChanges(e, null, 'use_fields_in_model')}
                                    >{
                                            props.NumericColumns.map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* does_data_contain_actionable_signal */}
                            <Col xs={24} className='content-margin-1'>
                                <Form.Item name='does_data_contain_actionable_signal' label="Does data contain actionable signal?">
                                    <Switch
                                        checked={dropdownValues['does_data_contain_actionable_signal']}
                                        onChange={(v, e) => handleDropDownChanges(e, v, 'does_data_contain_actionable_signal')} />
                                </Form.Item>
                            </Col>
                            {/* Complementary Data Status */}
                            <Col xs={24} className='content-margin-1'>
                                <Form.Item name='add_complemantry_market_data' label="Complementary Market Data Status">
                                    <Switch
                                        checked={dropdownValues['add_complemantry_market_data']}
                                        onChange={(v, e) => handleDropDownChanges(e, v, 'add_complemantry_market_data')} />
                                </Form.Item>
                            </Col>
                            {/* Out of Samples status */}
                            <Col xs={24} className='content-margin-1'>
                            {/* <Col xs={24}> */}
                                <Form.Item name='out_of_sample_status' label="Out Of Sample Status">
                                    <Switch
                                        checked={dropdownValues['out_of_sample_status']}
                                        onChange={(v, e) => handleDropDownChanges(e, v, 'out_of_sample_status')} />
                                </Form.Item>
                            </Col>
                            {dropdownValues['out_of_sample_status'] ?
                                <>
                                    <Col xs={24} className='content-margin-1'>
                                        <Form.Item
                                            rules={[{
                                                required: true,
                                                message: 'Please select a date!',
                                            }]}
                                            name='train_sample_range' label="Train the Model until">
                                            <DatePicker
                                                disabledDate={d =>
                                                    !d ||
                                                    d.isAfter(moment(props.stateValues['max_date'])) ||
                                                    d.isBefore(moment(props.stateValues['min_date']))}
                                                format="YYYY-MM-DD"
                                                defaultValue={moment(props.stateValues['min_date'])}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} style={{ margin: '0rem 1rem 1rem 0rem' }} className='content-margin-1'>
                                        <Form.Item
                                            rules={[{
                                                required: true,
                                                message: 'Please select a date!',
                                            }]}
                                            name='test_sample_range' label="Test the Model until">
                                            <DatePicker
                                                disabledDate={d =>
                                                    !d ||
                                                    d.isAfter(moment(props.stateValues['max_date'])) ||
                                                    d.isBefore(moment(props.stateValues['min_date']))}
                                                format="YYYY-MM-DD"
                                                defaultValue={moment(props.stateValues['min_date'])}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                                :
                                    <Col xs={24}
                                        className='content-margin-1'
                                        style={{ margin: '0rem 0rem 0rem 0rem' }}>
                                        <Form.Item
                                            rules={[{
                                                required: true,
                                                message: 'Please select a date!',
                                            }]}
                                            name='train_test_selection_input' label="Train and test the Model until">
                                            <DatePicker
                                                disabledDate={d =>
                                                    !d ||
                                                    d.isAfter(moment(props.stateValues['max_date'])) ||
                                                    d.isBefore(moment(props.stateValues['min_date']))}
                                                format="YYYY-MM-DD"
                                                defaultValue={moment(props.stateValues['min_date'])}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                            </>
                        }
                        {/*Notify Results At */}
                        <Col xs={8} className='content-margin-1'>
                            <Tooltip  title={"Please Enter your Email ID"} align={'left'} >
                                <Form.Item
                                    label="Notify Results At"
                                    name="notify_results_at"
                                    rules={[{
                                        type:"email",
                                        required: true,
                                        message: 'Please Provide your valied Email ID!',
                                    }]}
                                >
                                    <Input style={{width:300}} placeholder='xyz@gamil.com'></Input>
                                
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        {/* Submit Button */}
                        <Col xs={24} className='content-margin-1'>
                            <Button 
                            htmlType='submit'
                            variant='outlined'>
                                Basket Settings
                            </Button>
                        </Col>
                    </Row>
                    
                </Form>
            </>
    )
}
