import React from 'react'

export default function AppWs() {
    const [isPaused, setPause] = React.useState(false);
    
    const ws = React.useRef(null);

    React.useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WSS_STRING);
        ws.current.onopen = () => console.log("ws opened");
        // ws.current.onclose = () => console.log("ws closed");
        return () => {
            ws.current.close();
        };
    }, []);

    React.useEffect(() => {
        if (!ws.current) return;
        ws.current.onmessage = e => {
            if (isPaused) return;
            const message = JSON.parse(e.data);
            console.log("e", message);
        };
    }, [isPaused]);

    return (
        <>
            {/* <button onClick={() => setPause(!isPaused)}>
                {isPaused ? "Resume" : "Pause"}
            </button> */}
        </>
    );
}



