export const Brand_Mapping_Template = [
    {"data_name":"Motorola","data_type":"Business entity","data_context":"Consumer electronics"," ":" "," ":" "," ":" ","Supported data types":"Business entity"},
    {"data_name":"Whatsapp","data_type":"Mobile app","data_context":"Communications platform"," ":" "," ":" "," ":" ","Supported data types":"Mobile app"},
    {"data_name":"Fitbit","data_type":"Mobile app","data_context":"Health/Fitness company"," ":" "," ":" "," ":" ","Supported data types":"Website"},
    {"data_name":"www.linkedin.com","data_type":"Website","data_context":"social networking app"," ":" "," ":" "," ":" ","Supported data types":"Product"},
    {"data_name":"Nutrogena facial 250ml","data_type":"Product","data_context":"Face wash"," ":" "," ":" "," ":" ","Supported data types":""},
    {"data_name":"Cerner","data_type":"Business entity","data_context":""," ":" "," ":" "," ":" ","Supported data types":""}

]

export const Althub_Mapping_Template = [
    {
        "Symbol":"MMM"	,
        "Composite Ticker":"MMM:US",	
        "Comapny Name" : "3M Co.",
        "Figi Ticker" : "MMM:UN",	
        "ISIN":"US88579Y1010",
        "Bloomberg Global Id":"BBG000BP52R2",
        "Bloomberg Unique Id":"EQ0010100100001000",
        "Currency":"USD"
    },
    {
        "Symbol":"BIRG.L",
        "Composite Ticker":"BIRG:LN",	
        "Comapny Name" : "Bank of Ireland Group Plc",
        "Figi Ticker" : "BIRG:LN",	
        "ISIN":"IE00BD1RP616",
        "Bloomberg Global Id":"BBG00GF7SCP8",
        "Bloomberg Unique Id":"EQ0000000056072741",
        "Currency":"EUR"
    },
    {
        "Symbol":"BME.L",
        "Composite Ticker":"BME:LN",	
        "Comapny Name" : "B&M European Value Retail S.A.",
        "Figi Ticker" : "BME:LN",	
        "ISIN":"LU1072616219",
        "Bloomberg Global Id":"BBG006K4W740",
        "Bloomberg Unique Id":"EQ0000000035834616",
        "Currency":"GBP"
    },
    {
        "Symbol":"ABBV",
        "Composite Ticker":"ABBV:US",	
        "Comapny Name" : "Abbvie Inc",
        "Figi Ticker" : "ABBV:UN",	
        "ISIN":"US00287Y1091",
        "Bloomberg Global Id":"BBG0025Y4RY4",
        "Bloomberg Unique Id":"EQ0000000020553113",
        "Currency":"USD"
    }
]

