import React, { useState } from 'react'
import axios from 'axios';
import moment from 'moment';
// import PasswordResetForm from './PasswordResetForm';
import { Card,Upload,Typography, Modal,Input,DatePicker,Select,Button,message,Image,Form } from 'antd';
import { EditOutlined, UploadOutlined, SettingOutlined } from '@ant-design/icons';
const logo = require('../../../../assets/images/althubLogo.png')

export default function Profile({ login,details }) {   
    const [editVisible,setEditVisible] = React.useState(false)
    const [changePasswordVisible,setChangePasswordVisible] = React.useState(false)
    const [buttonLoading, setButtonLoading] = React.useState(false)
    // const [details, setDetails] = React.useState(login.user)
    // const [details, setDetails] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [authToken, setAuthToken] = useState('');
    // const [logo , setLogo ] = React.useState(login.user.profile_picture_url)
    const [form] = Form.useForm();

    // const getUserData = () =>{
    //     axios({
    //         method: 'GET',
    //         url: `/users/?id=${login.user.id}`,  
    //     }).then(res => {
    //         setDetails(res.data.results[0])
    //         localStorage.setItem('profile_picture_url',JSON.stringify(res.data.results[0].profile_picture_url?res.data.results[0].profile_picture_url:''))
    //         // message.success('Updated successfully')
    //     }).catch(err => {
    //         message.error(err.response.data.data)
    //     }).finally(() => {
    //     // setVisible(false);
    //     setLoading(false)
    // })

    // };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList[0]?.originFileObj;
    };

    const onCreate = (values) => {
        const Data = new FormData()
        if (values.first_name) Data.append('first_name', values.first_name)
        if (values.last_name) Data.append('last_name', values.last_name)
        if (values.phone_number) Data.append('phone_number', values.phone_number)
        if (values.profile_picture) Data.append('profile_picture', values.profile_picture)
        if (values.designation) Data.append('designation', values.designation)
        if (values.access_type) Data.append('access_type', values.access_type)
        axios({
            method: 'PATCH',
            url: `/users/${login.user.id}/`,
            data: Data  
        }).then(res => {
            // getUserData()
            message.success('Updated successfully')
        }).catch(err => {
            message.error(err.response.data.data)
        }).finally(() => {
            setEditVisible(false);
            window.location.reload()
    })
    };

    const getTokenApi = () => {
        axios({
            method: 'POST',
            url: `/users/get_api_token/`,
            data: { key: login.auth_token }
        }).then(res => {
            setAuthToken(res.data['API Key']);
            message.success('API key generated successfully');
        }).catch(err => {
            message.error(err.response.data.data);
        });
    };

    const Editdetaislform = ()=>{
        setEditVisible(true)
    };

    const Editpasswordform = ()=>{
        setChangePasswordVisible(true)
    };

    const submitNewPassword = (values) => {
        if (values.new_password === values.confirm_password){
            axios.post('/users/change_password/', {
                old_password: values.old_password,
                new_password: values.new_password,
                confirm_password:values.confirm_password,
            }).then(res => {
                setChangePasswordVisible(false);
                message.success('Password changed successfully')
                form.resetFields();
            }).catch(err => {
                message.error(err.response.data.message)
            }).finally(() => {
                setButtonLoading(false)
                // setChangePasswordVisible(false);
                // window.location.reload()
            })
        }
        else{
            setButtonLoading(false)
            message.error("New Password and Confirm Password are not matched.")
        }
    }

    // React.useMemo(()=>{
    //     getUserData()
    // },[]);

    return (
        <>
            <Card
                // style={{ width: 300 }}
                cover={
                    // <img
                    //     alt="Profile Picture"
                    //     // src={localStorage.getItem('profile_picture_url') !== ''? localStorage.getItem('profile_picture_url'):logo}
                    //     src={details.profile_picture_url?details.profile_picture_url:logo}
                    // />
                    <Image 
                        alt="Profile Picture"
                        src={details.profile_picture_url?details.profile_picture_url:logo}
                        preview={{
                            className:'Glass',
                            src : details.profile_picture_url?details.profile_picture_url:logo,
                        }}

                    />
                }
                // loading={loading}
                actions={[
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button onClick={Editdetaislform}>Edit Details</Button>
                        <Button onClick={getTokenApi}>Get API Token</Button>
                        <Button onClick={Editpasswordform}>Reset Password</Button>
                    </div>
                    // <SettingOutlined key="setting" />,
                    // <EditOutlined key="edit" onClick={Editdetaislform} />,
                ]}
            >
                <Card.Meta
                    // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title="My Profile"
                    description={String.fromCharCode(160)}
                />
                <Typography.Text level={4}>Name : {details?.first_name} {details?.last_name}</Typography.Text>
                <br />
                <Typography.Text level={4}>Email : {details?.email}</Typography.Text>
                <br />
                <Typography.Text level={4}>Designation : {details?.designation}</Typography.Text>
                <br />
                <Typography.Text level={4}>Access Type : {details?.access_type}</Typography.Text>
                <br />
                <Typography.Text level={4}>Company Name : {details?.client?.client_name}</Typography.Text>
                <br />
                <Typography.Text level={4}>Company Type : {details?.client?.company_type}</Typography.Text>
                <br />
                <Typography.Text level={4}>License Type : {details?.client?.license?.license_type}</Typography.Text>
                <br />
                <Typography.Text level={4}>License Valid Until : {details?.client?.validity}</Typography.Text>
                <br />
                <Typography.Text level={4}>UserKey : {login.auth_token}</Typography.Text>
            </Card>

            {/* Form for edit details*/}
            <Modal
                open={editVisible}
                title="Edit Details"
                okText="Update"
                cancelText="Cancel"
                onCancel={() => {
                    setEditVisible(false);
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            values.profile_picture = values.profile_picture?values.profile_picture:null
                            if (values.phone_number == "N/A" ||values.phone_number == "" ) values.phone_number=null
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="user_form_in_modal_Edit_Client_Data"
                    initialValues={{
                        first_name:details?.first_name,
                        last_name:details?.last_name,
                        phone_number:details.phone_number?details.phone_number:"",
                        email:details?.email,
                        designation:details?.designation,
                        access_type:details?.access_type,
                      }}
                >
                    <Form.Item
                        name="first_name"
                        label="First Name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        label="Last Name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="phone_number"
                        label="Phone Number"
                    >
                        <Input />
                    </Form.Item>

                    {details.id == 1 && <Form.Item
                        name="email"
                        label="Email"
                    >
                        <Input type={'email'} disabled= {true} />
                    </Form.Item>}

                    {details.id == 1 && <Form.Item
                        name="designation"
                        label="Designation"
                    >
                        <Input />
                    </Form.Item>}

                    {details.id == 1 && <Form.Item
                        name="access_type"
                        label="Access Type"
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                        >
                                <Select.Option value={"global-admin"}>Global Admin</Select.Option>
                                <Select.Option value={"admin"}>Admin</Select.Option>
                                <Select.Option value={"write"}>Write</Select.Option>
                                <Select.Option value={"read_only"}>Read Only</Select.Option>
                        </Select>
                    </Form.Item>}

                    {/* {details.id == 1 && <Form.Item
                        name="company_name"
                        label="Company Name"
                    >
                        <Input />
                    </Form.Item>}

                    {details.id == 1 && <Form.Item
                        name="company_type"
                        label="Company Type"
                    >
                        <Select>
                            <Select.Option value={"alternative Data Provider"}>Alternative Data Provider</Select.Option>
                            <Select.Option value={"Asset Manager"}>Asset Manager</Select.Option>
                            <Select.Option value={"Hedge Fund"}>Hedge Fund</Select.Option>
                            <Select.Option value={"Wealth Manager"}>Wealth Manager</Select.Option>
                            <Select.Option value={"other"}>Other</Select.Option>
                        </Select>
                    </Form.Item>}

                    {details.id == 1 && <Form.Item
                        name="license_type"
                        label="License Type"
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                        >
                            {buildDataSetOptions(license_type[0])}
                        </Select>
                    </Form.Item>}

                    {details.id == 1 && <Form.Item
                        name="validity"
                        label="License Valid Until"
                    >
                        <></>
                        <DatePicker defaultValue={details.client?.validity && moment(details.client?.validity)} />
                        
                    </Form.Item>} */}

                    <Form.Item name="profile_picture" label="Profile Picture" getValueFromEvent={normFile}>
                        <Upload name="profile_picture_upload"
                            maxCount={1}
                            beforeUpload={() => false}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Form for Change password*/}
            <Modal
                    open={changePasswordVisible}
                    title="Change Password"
                    okText="Update"
                    cancelText="Cancel"
                    footer={[
                        <Button key="cancel" onClick={() => {setChangePasswordVisible(false);form.resetFields();}}>
                          Cancel
                        </Button>,
              
                        <Button
                            type='primary'
                            loading={buttonLoading}
                            onClick={()=>{
                                setButtonLoading(true)
                                form.validateFields()
                                    .then((values) => {
                                        submitNewPassword(values);
                                    })
                                    .catch((info) => {
                                        setButtonLoading(false)
                                        console.log('Validate Failed:', info);
                                    });
                        }}>Update</Button>
                      ]}
                    onCancel={()=>{
                        setChangePasswordVisible(false);
                    }}
                    // onOk={() => {
                        
                    //     form
                    //         .validateFields()
                    //         .then((values) => {
                    //             submitNewPassword(values);
                    //         })
                    //         .catch((info) => {
                    //             console.log('Validate Failed:', info);
                    //         });
                            
                    // }}
                >
                    <Form
                        form={form}
                        layout="horizontal"
                        name="NEW_Password_form_in_modal"
                    >
                        <Form.Item
                            name="old_password"
                            label="Old Password"
                            rules={[
                                {
                                    required: true,
                                    // message: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                                    // pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Enter old password" />
                        </Form.Item>
    
                        <Form.Item
                            name="new_password"
                            label="New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                                    pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Enter new password" />
                        </Form.Item>
    
                        <Form.Item
                            name="confirm_password"
                            label="Confirm Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Same as above',
                                    pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Re-type the password" />
                        </Form.Item>
                    </Form>
            </Modal>

            <Modal
                open={authToken !== ''}
                title="User API Key"
                onCancel={() => {
                    setAuthToken('');
                }}
                footer={[
                    <Button key="ok" onClick={() => { setAuthToken(''); }}>
                        OK
                    </Button>
                ]}
            >
                <Typography.Text  style={{display:"flex",justifyContent:"start"}}> Key: &nbsp;<Typography.Text copyable="true" >{authToken}</Typography.Text> </Typography.Text>

            </Modal>
        </>
    )
}
