import React from 'react'
import axios from 'axios'
import SecurityMap from "./SecurityMap";
import { Select, Card, message, Row, Col, Tooltip, Typography, Button, Switch} from 'antd';


export default function ColumnSelection(props) {

    const [BrandmappingColumn, setBrandmappingColumn] = React.useState(null)
    const [brandType, setbrandType] = React.useState('')
    const [InputChanges, setInputChanges] = React.useState([])
    const [InputReplaceFile, setInputReplaceFile] = React.useState([])
    const [Loader1, setLoader1] = React.useState(false)
    const [PointInTime,setPointInTime] = React.useState(false)
    const [CurrentSymbology,setCurrentSymbology] = React.useState(false)
    const [mapping_columns, setmapping_columns] = React.useState(props.stateValues.BrandMappedColumns)

    const [brandContextcolumn, setbrandContextcolumn] = React.useState('')
    const [SecondComboBoxOptions, setSecondComboBoxOptions] = React.useState([])
    const [ThirdComboBoxOptions, setThirdComboBoxOptions] = React.useState([])

    const handleAfterModified = (data, dataFile) => {
        setInputChanges(data === null ? [] : JSON.stringify(data))
        props.handleStateChange(null,data,'InputChanges')
        setInputReplaceFile(dataFile === null ? [] : dataFile)
        props.handleStateChange(null,dataFile,'InputReplaceFile')

    }

    const handleColumnsChange = (value) => {
        if (value == true){
            setmapping_columns(props.PIT_BrandMappedColumns)
        }
        else{
            setmapping_columns(props.BrandMappedColumns)
        }
    }

    const handleMapSecurities = () => {
        props.setloader(true)
        setLoader1(true)
        const Data = new FormData()
        Data.append('initial_mapping', true)
        Data.append('security_mapping_file', props.stateValues['user_uploaded_file'])
        let stop = false
        if (BrandmappingColumn == null) {
            message.error('Please Select Brand Mapping Column',2);
            setLoader1(false);
            stop=true
            return
        }
        if (brandType == null ) {
            message.error('Please Select Brand Type Column',2);
            setLoader1(false);
            stop=true
            return
        }
        if (brandContextcolumn == null ) {
            message.error('Please Select Brand Context Column',2);
            setLoader1(false);
            stop=true
            return
        }

        Data.append('brand_name',BrandmappingColumn)
        Data.append('brand_type',brandType)
        Data.append('brand_context_column',brandContextcolumn)
        if (stop == true){setLoader1(false); return}

        Data.append('input_changes', InputChanges)
        Data.append('input_replace_file', InputReplaceFile)
        Data.append('point_in_time',PointInTime)
        
        let url="/securities/details/BrandMapping/"
        axios({
            method: 'post',
            url: url,
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: Data
        }).then(res => {
            if (res.data.hasOwnProperty('message')){
                message.success(res.data.message,3)
            }
            else{

            if(PointInTime){
                props.handleStateChange(null,mapping_columns,'Act_Map_columns');
            }
            else{
                props.handleStateChange(null,props.BrandMappedColumns,'Act_Map_columns');
            }
            let mappedData = res.data['mapped_securities'].map((x)=>{
                let result = {}
                result['Data'] = x.data?x.data:''
                result['Company'] = x.company?x.company:''
                result['Company Type'] = x.company_type?x.company_type:''
                result['Parent Company'] = x.parent?x.parent:''
                result['Parent Company Type'] = x.parent_type?x.parent_type:''
                // result['Parent Company Symbol'] = x.parent_symbol?x.parent_symbol:''
                result['Company Symbol'] = x.company_symbol?x.company_symbol:''
                result['ISIN'] = x.isin?x.isin:''
                result['Exchange Code'] = x.mic?x.mic:''

                // if (PointInTime){
                //     result["Valid From"]= x.valid_from
                //     result['Valid To'] = x.valid_to
                // }
                return result
                // return{
                //     "Data":x.data_name,
                //     "Company":x.company,
                //     "Company Type":x.company_type,
                //     "Company Symbol":x.company_symbol,
                //     "Parent Company":x.parent,
                //     "Parent Company Type":x.parent_type,
                //     "Parent Company Symbol":x.parent_symbol,
                // }
            })
                let multimappedData = res.data?.multi_mapped_securities?.map((x)=>{

                let result = {}
                if (!PointInTime){
                    result['Data'] = x.data?x.data:''
                    result['Company'] = x.company?x.company:''
                    result['Company Type'] = x.company_type?x.company_type:''
                    result['Parent Company'] = x.parent?x.parent:''
                    result['Parent Company Type'] = x.parent_type?x.parent_type:''
                    result['Parent Company Symbol'] = x.parent_symbol?x.parent_symbol:''
                    result['Company Symbol'] = x.company_symbol?x.company_symbol:''
                    result['ISIN'] = x.isin?x.isin:''
                    result['Exchange Code'] = x.mic?x.mic:''
                }

                if (PointInTime){
                    result['Data'] = x.data?x.data:''
                    result['Parent Company'] = x.parent?x.parent:''
                    result['Company Type'] = x.company_type?x.company_type:''
                    result['Symbol'] = x.symbol?x.symbol:''
                    // result["Valid From"]= x.valid_from
                    // result['Valid To'] = x.valid_to
                    result['ISIN'] = x.isin?x.isin:''
                    result['Exchange Code'] = x.mic?x.mic:''
                }
                return result
                // return{
                //     "Data":x[props.stateValues.BrandmappingColumn],
                //     "Parent Company":x.parent_name,
                //     "Company Type":x.company_type,
                //     "Symbol":x.symbol,
                //     "Accuracy":x.accuracy,
                //     "Country":x.country_name,
                //     "Currency":x.currency_code,
                //     "Figi Ticker":x.figi_ticker,
                //     "Composite Ticker":x.composite_ticker,
                // }
            })
            props.handleStateChange(null,mappedData,'mappedData')    
            props.handleStateChange(null,multimappedData,'multimappedData')
            props.handleStateChange(null,res.data,'allSecurityData')
            props.handleStateChange(null,res.data.count,'total')
            props.handleStateChange(null,res.data['mapped_count'],'mappedcount')
            props.handleStateChange(null,res.data['unmapped_securities'].length,'unmappedcount')
            props.handleStateChange(null,res.data['multimap_count'],'multimappedcount')
            

            // props.setallSecurityData(res.data)
            // props.settotal(res.data.count)
            // props.setmappedcount(res.data['mapped_securities'].length)
            // props.setunmappedcount(res.data['unmapped_securities'].length)
            // props.setmultimappedcount(res.data['multimap_count'])
            }
        }).catch(err => {
            // props.setallSecurityData(null)
            props.handleStateChange(null,null,'allSecurityData')
            err.response?.data?.message ? message.error(`${err.response?.data?.message}`, 2) : err.response?.data?.detail ?message.error(`${err.response?.data?.detail}`, 2): message.error('There Is An Internal Issue While Mapping', 2);
        }).finally(() => {
            props.setloader(false)
            setLoader1(false)
        })
    }
return(
    <Col xs={24} sm={24} md={18} lg={18} xl={16} xxl={18} value={100} >
        <Card className='Glass Symlink-card-2' style={{height:'100%'}}>
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <Row gutter={[16,8]} style={{paddingBottom:"28px"}}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div style={{lineHeight:'32px'}}>
                            <Typography.Text  className='typography-text-type-1' >Link your data to ultimate parents and their trading symbols</Typography.Text>
                        </div>
                        </Col>
                    </Row>
                    <Row gutter={[18,18]} >
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                            <Select
                                style={{ width: '100%'}}
                                allowClear
                                // onChange={(v)=>{props.setBrandmappingColumn(v);setBrandmappingColumn(v)}}
                                onChange={(v)=>{
                                    props.handleStateChange(null,v,'BrandmappingColumn');
                                    setBrandmappingColumn(v);
                                    if(v)setSecondComboBoxOptions(props.stateValues['user_uploaded_headers'].filter(x=> {if(x != v) return x}))
                                    else {setSecondComboBoxOptions([]);setThirdComboBoxOptions([]);setbrandType(null);setbrandContextcolumn(null)}      
                                }}

                                // mode='multiple'
                                placeholder={'Select the column that contains the data'}
                                // placeholder={y.value}
                            >{props.stateValues['user_uploaded_headers'].map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)}
                            </Select>
                        </Col>
                        {<Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                            {/* <Select
                                allowClear
                                style={{ width: '100%'}}
                                placeholder={'Your data belongs to (select all applicable)'}
                                mode='multiple'
                                showArrow={true}
                                // onChange={v=>{props.setbrandType(v);setbrandType(v)}}
                                onChange={v=>{props.handleStateChange(null,v,'brandType');setbrandType(v)}}
                            >
                                <Select.Option value='product'><Tooltip title={'Individual products created by companies. Can also be brands in some cases E.g. Coco Pops is a product of Kelloggs and also a brand'}>Products</Tooltip></Select.Option>
                                <Select.Option value='brand'><Tooltip title={'All types of private and public businesses from small merchants to global corporations. Brands can also be registered businesses. E.g. Gatorade'}>Business Entities</Tooltip></Select.Option>
                                <Select.Option value='web_domain'><Tooltip title='Web Domains'>Web Domains</Tooltip></Select.Option>
                                <Select.Option value='mobile_app'><Tooltip title = 'Mobile Apps'>Mobile Apps</Tooltip></Select.Option>
                            </Select> */}
                            <Select
                                style={{ width: '100%'}}
                                allowClear
                                onChange={v=>{
                                    props.handleStateChange(null,v,'brandType');
                                    setbrandType(v);
                                    if (v)setThirdComboBoxOptions(SecondComboBoxOptions.filter(x=> {if(x != v) return x}))
                                    else {setThirdComboBoxOptions([]);setbrandContextcolumn(null)}
                                }}
                                // mode='multiple'
                                placeholder={'Select the column that contains the data type'}
        
                            >{SecondComboBoxOptions.map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)}
                            </Select>
                        </Col>}
                        {<Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                            <Select
                                style={{ width: '100%'}}
                                allowClear
                                onChange={v=>{props.handleStateChange(null,v,'brandContextColumn');setbrandContextcolumn(v)}}
                                // mode='multiple'
                                placeholder={'Select the column that contains the data context'}
        
                            >{ThirdComboBoxOptions.map((x, i) => <Select.Option key={i} value={x}>{x}</Select.Option>)}
                            </Select>
                        </Col>}
                    </Row>                           
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingTop:'14px'}}>
                     <div style={{display:'flex'}} >
                        <div className=' Glass custom-switch2' style={{justifyContent:'center'}}>
                            <div className='div-with-button ' style={{justifyContent:'center'}}>
                                <Button className='custom-button2' style={{padding: '3px 10px'}} type='text'  strong size='medium' onClick={()=>{setselectdbutton(!selectdbutton)}} disabled={false}>Current Symbology</Button>
                                <Button className='custom-button2' style={{padding: '3px 10px'}} type='text' strong size='medium' onClick={()=>{setselectdbutton(!selectdbutton)}} disabled={true} >Point In Time Symbology</Button>
                            </div>
                        </div>
                    </div>  
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingTop:'14px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{display:'flex'}}>
                            <Typography.Text className='double-switch2 typography-text-type-2' ><strong>Linking Type:</strong></Typography.Text>
                            <Typography.Text className='double-switch2 typography-text-type-2 'style={{paddingLeft:'10px'}} >
                                <Tooltip title = {'This links the data to the current parent company'}> Current Symbology</Tooltip> 
                                <div style={{display:"inline" ,padding: "0px 10px 0px 10px"}}><Switch disabled={false} checked={PointInTime} onChange={(v) => {setPointInTime(v); props.handleStateChange(null,v,'point_in_time'); handleColumnsChange(v) }} ></Switch></div>
                            </Typography.Text>
                        </div>
                        <Typography.Text className='double-switch2 typography-text-type-2'>
                            <Tooltip title={'This links the data to all the parent companies that owned it in the past along with the current parent company'}>Point In Time Symbology</Tooltip>
                        </Typography.Text>
                    </div>
                </Col>
                {/* <Col span={24}>
                <Divider style={{padding:'0px',marginTop:'60px',marginBottom:'28px', border:'1px dashed #363636'}}></Divider>
                </Col> */}
                <Col >
                    <Button type='primary' htmlType='submit' style={{color:'#161616'}} onClick={()=>{handleMapSecurities()}} loading={Loader1}>Link to Parent Symbols</Button>
                </Col>
                {/* <Col style={{paddingTop:'40px'}}>
                    {props.stateValues['user_uploaded_headers'] && <SecurityMap handleAfterModified={handleAfterModified} colList={props.stateValues['user_uploaded_headers']} />}
                </Col> */}
            </Row>
        </Card>
    </Col>
)}