import React from "react";
import axios from "axios";
import { Collapse, Select, message, Upload, AutoComplete, Switch, Form, Card, Row, Col, Button, Typography } from 'antd';
import { SettingOutlined, InboxOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
// import SecurityMapping from "../SignalGeneration/SecurityMapping";
import csv from '../../../assets/images/csv.png'
import { Brand_Mapping_Template } from "./data";
import { CSVTOFILE,JSONTOCSV, DownloadFile } from '../../Settings/commonFunctions'
import {DownloadOutlined} from '@ant-design/icons';
import ColumnSelection from "./_components/ColumnSelections";
import GetMappedTables from "./_components/GetMappedTables";
import SecurityMapping from "./_components/SecurityMapping";

const BrandMappedColumns = [
    { 
        key: 'brand_name', dataIndex: 'brand_name', title: 'Data',
        sorter: (a, b) => {if (a.brand_name!=null)
            return a.brand_name.localeCompare(b.brand_name);
        else if(b.brand_name!=null)
            return b.brand_name.localeCompare(a.brand_name);},
    },
    {
        key: 'company', dataIndex: 'company', title: 'Company',
        sorter: (a, b) => {if (a.company!=null)
            return a.company.localeCompare(b.company);
        else if(b.company!=null)
            return b.company.localeCompare(a.company);},
    },
    {
        key: 'company_type', dataIndex: 'company_type', title: 'Company Type',
        sorter: (a, b) => {if (a.company_type!=null)
            return a.company_type.localeCompare(b.company_type);
        else if(b.company_type!=null)
            return b.company_type.localeCompare(a.company_type)},
        filters:[{
            text: 'Public',
            value: 'Public',
          },
          {
            text: 'Private',
            value: 'Private',
          },],
          onFilter: (value, record) => record.company_type.indexOf(value) === 0
    },
    {
        key: 'parent', dataIndex: 'parent', title: 'Parent Company',
        sorter: (a, b) => {if (a.parent!=null)
            return a.parent.localeCompare(b.parent);
        else if(b.parent!=null)
            return b.parent.localeCompare(a.parent);},
    },
    // { 
    //     key: 'parent_symbol', dataIndex: 'parent_symbol', title: 'Parent Symbol',
    //     sorter: (a, b) => {if (a.parent_symbol!=null)
    //         return a.parent_symbol.localeCompare(b.parent_symbol);
    //     else if(b.parent_symbol!=null)
    //         return b.parent_symbol.localeCompare(a.parent_symbol)},
        
    // },
    { 
        key: 'parent_type', dataIndex: 'parent_type', title: 'Parent Company Type',
        sorter: (a, b) => {if (a.parent_type!=null)
            return a.parent_type.localeCompare(b.parent_type);
        else if(b.parent_type!=null)
            return b.parent_type.localeCompare(a.parent_type);},
        filters:[{
            text: 'Public',
            value: 'Public'||'public',
            },
            {
            text: 'Private',
            value: 'Private',
            },],
            onFilter: (value, record) => record.parent_type.indexOf(value) === 0
    },
    { 
        key: 'company_symbol', dataIndex: 'company_symbol', title: 'Company Symbol',
        sorter: (a, b) => {if (a.company_symbol!=null)
            return a.company_symbol.localeCompare(b.company_symbol);
        else if(b.company_symbol!=null)
            return b.company_symbol.localeCompare(a.company_symbol)},
        
    },
    { 
        key: 'isin', dataIndex: 'isin', title: 'ISIN',
        sorter: (a, b) => {if (a.isin!=null)
            return a.isin.localeCompare(b.isin);
        else if(b.isin!=null)
            return b.isin.localeCompare(a.isin);},
    },
    { 
        key: 'mic', dataIndex: 'mic', title: 'Exchange Code',
        sorter: (a, b) => {if (a.mic!=null)
            return a.mic.localeCompare(b.mic);
        else if(b.mic!=null)
            return b.mic.localeCompare(a.mic);},
    },
    
]

const PIT_BrandMappedColumns = [
    { 
        key: 'brand_name', dataIndex: 'brand_name', title: 'Data',
        sorter: (a, b) => {if (a.brand_name!=null)
            return a.brand_name.localeCompare(b.brand_name);
        else if(b.brand_name!=null)
            return b.brand_name.localeCompare(a.brand_name);},
    },
    { 
        key: 'company', dataIndex: 'parent', title: 'Parent Company',
        sorter: (a, b) => {if (a.parent!=null)
            return a.parent.localeCompare(b.parent);
        else if(b.parent!=null)
            return b.parent.localeCompare(a.parent);},
    },
    {
        key: 'company_type', dataIndex: 'company_type', title: 'Company Type',
        sorter: (a, b) => {if (a.company_type!=null)
            return a.company_type.localeCompare(b.company_type);
        else if(b.company_type!=null)
            return b.company_type.localeCompare(a.company_type)},
        filters:[{
            text: 'Public',
            value: 'Public',
          },
          {
            text: 'Private',
            value: 'Private',
          },],
          onFilter: (value, record) => record.company_type.indexOf(value) === 0
    },
    { 
        key: 'symbol', dataIndex: 'symbol', title: 'Symbol',
        sorter: (a, b) => {if (a.symbol!=null)
            return a.symbol.localeCompare(b.symbol);
        else if(b.symbol!=null)
            return b.symbol.localeCompare(a.symbol)},
        
    },
    // {key: 'valid_from', dataIndex:'valid_from', title:'Valid From'},
    // {key: 'valid_to', dataIndex:'valid_to', title:'Valid To'},
    { 
        key: 'isin', dataIndex: 'isin', title: 'isin',
        sorter: (a, b) => {if (a.isin!=null)
            return a.isin.localeCompare(b.isin);
        else if(b.isin!=null)
            return b.isin.localeCompare(a.isin);},
    },
    { 
        key: 'mic', dataIndex: 'mic', title: 'mic',
        sorter: (a, b) => {if (a.mic!=null)
            return a.mic.localeCompare(b.mic);
        else if(b.mic!=null)
            return b.mic.localeCompare(a.mic);},
    },
   
]

const stateTemplate = {
    user_uploaded_file: null,
    user_uploaded_headers: [],
    min_date: null,
    max_date: null,
    allSecurityData:null,
    BrandmappingColumn:null,
    brandType:null,
    brandContextColumn:null,
    total : 0,
    mappedcount : 0,
    multimappedcount : 0,
    unmappedcount : 0,
    InputChanges : null,
    InputReplaceFile : null,
    mappedData:null,
    unmappedData:null,
    multimappedData:null,  
    point_in_time:false,
    Act_Map_columns : BrandMappedColumns,
}


export default function Tickerization() {
    const [stateValues, setstateValues] = React.useState(stateTemplate);
    const [loader, setloader] = React.useState(false)

    const props = {
        name: 'file',
        maxCount: 1,
        beforeUpload: () => false,
        onChange(filesObject) {
            setstateValues(stateTemplate)
            if (filesObject.fileList.length === 0) {
                setstateValues(stateTemplate)
                return
            }
            uploadSecurityMappingFile(filesObject.fileList[0])
        },
    };

    const uploadSecurityMappingFile = (file) => {

        if ((file.size / 1024 / 1024) > 5 && file.name.split(".")[1].toLowerCase() === "csv") {
          message.error(`File size is ${parseFloat(file.size / 1024 / 1024).toFixed(2)} MB, Please upload files less than or equal to 5MB file`,2)
          return
        }
        if (file.name.split(".")[1].toLowerCase() !== "csv"){
          message.error(`File extension should be '.csv'`,2)
          return
        }
    
        if (file.name.split(".")[1].toLowerCase() === "csv") {
          var obj_csv = {
            size: 0,
            dataFile: []
          };
          let reader = new FileReader();
          reader.readAsBinaryString(file.originFileObj);
          reader.onload = function (e) {
            obj_csv.size = e.total;
            obj_csv.dataFile = e.target.result
            let user_uploaded_headers = obj_csv.dataFile.split(/\r?\n/)[0].split(/,/).filter(x=>{if (![" ","","Supported data types"].includes(x)) return x})
            handleStateChange(null, user_uploaded_headers, "user_uploaded_headers")
          }
    
          handleStateChange(null, file.originFileObj, "user_uploaded_file")
        }
    }

    const handleStateChange = (e, v, element) => {
      setstateValues(prevState => ({ ...prevState, [element]: v }));
    }
    const modifyEntireState = (data) => setstateValues(data);

    const handleSampleFileSave = () =>{
        DownloadFile(JSONTOCSV(Brand_Mapping_Template), "Sample_upload_data_symlink")
  }

    return (
        <>
            {/* Old Design */}
            {/* <Row gutter={[16,0]}>
                <Col span={6} >
                        <Form.Item label="" valuePropName="checked" className="custom-uploader">
                            <Upload.Dragger {...props} className="Glass">
                                <img src={csv} alt="csv" width={'50px'} height={'50px'} />
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-text">Mapping Column should be Unique values and Uploaded file contain only mapping columns</p>
                                <p className="ant-upload-hint">Support '.csv' files only.</p>
                            </Upload.Dragger>
                        </Form.Item>
                </Col>
                {stateValues['user_uploaded_file'] !== null &&
                <Col span={24}>
                        <SecurityMapping
                            handleStateChange={handleStateChange}
                            stateValues={stateValues}
                            modifyEntireState={modifyEntireState}
                        />
                </Col>}
            </Row> */}

            {/* New Design */}
            <Row gutter={[18,18]}>
                <Col xs={24} sm={24} md={6} lg={6} xl={8} xxl={6}>
                    <Row gutter={[0,16]}>
                        <Col span={24} style={{padding:'0px',margin:'0px'}} >          
                            {/* <Form.Item label="" valuePropName="checked" className="custom-uploader"> */}
                            <div className="custom-uploader">
                                <Upload.Dragger {...props} className="Glass" showRemoveIcon style={{padding:'0px 5px'}} >
                                    {/* <br /> */}
                                    <img src={csv} alt="csv" width={'50px'} height={'50px'} />
                                    <p className="ant-upload-text">Click or drag file to upload</p>
                                    {/* <p className="ant-upload-hint">Supports '.csv' files only upto 5MB size</p> */}
                                    <p className="ant-upload-hint">Supports '.csv' files only. Results are shown on screen only if the file has up to 25 rows in Current Symbology and 15 rows in Point In Time</p>
                                    {/* <br /> */}
                                </Upload.Dragger>
                            {/* </Form.Item> */}
                            </div>
                        </Col>
                        <Col span={24} style={{margin:'0px' ,padding:'0px'}}>
                            <Button style={{width:'100%',margin:'0px',padding:'0px'}} onClick={handleSampleFileSave} type='primary' ghost icon={<DownloadOutlined />}>Sample File</Button>
                        </Col>
                    </Row>
                </Col>
                {stateValues['user_uploaded_file'] !== null &&
                    <ColumnSelection
                        handleStateChange={handleStateChange}
                        stateValues={stateValues}
                        modifyEntireState={modifyEntireState}
                        setloader = {setloader}
                        BrandMappedColumns = {BrandMappedColumns}
                        PIT_BrandMappedColumns = {PIT_BrandMappedColumns}
                    />
                }
                {stateValues['user_uploaded_file'] !== null && stateValues.allSecurityData && <Col span={24}>                
                    <div className='Glass is-center' style={{lineHeight:'50px',borderRadius:'12px',border:'1px solid #2C2908',backgroundColor:'#1B1B15'}}>
                        <Typography.Text className='typography-table-description'>Out of the {stateValues.total} unique values, {stateValues.mappedcount}  are successfully linked to stock symbols. Could not find stock symbols for {stateValues.unmappedcount} and {stateValues.multimappedcount} records need to be reviewed</Typography.Text>
                    </div>
                </Col>}
                {stateValues['user_uploaded_file'] !== null && stateValues.allSecurityData &&
                  <GetMappedTables
                    handleStateChange={handleStateChange}
                    stateValues={stateValues}
                    modifyEntireState={modifyEntireState}
                    loader = {loader}
                    setloader = {setloader}
                    BrandMappedColumns = {BrandMappedColumns}
                    PIT_BrandMappedColumns = {PIT_BrandMappedColumns}
                  />
                }
            </Row>
        </>
      );
}
