import { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { defSetting } from './invisage/Settings/globalAxios'

// import appbg from "./assets/images/invisageBackground.jpg";
import { createBrowserHistory } from 'history';

import { AuthProvider } from "./invisage/Settings/useAuth";

import { Result, message, Layout } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

//Styles 
import './assets/scss/main.css';
import './assets/test.css';
// import 'antd/dist/antd.css';
// import 'antd/dist/antd.dark.css';
// import 'antd/dist/antd.dark.less';
// import 'antd/dist/antd.compact.css';

// Main Component import 
import { App } from "./invisage/___landing/landing";

// Force Application time zone to UK
import moment from 'moment'
require('moment-timezone')
moment.tz.setDefault('Europe/London')

const history = createBrowserHistory();
const app = document.getElementById("root");

// Tidio Chatbot code

// const tidioKey = process.env.REACT_APP_TIDIO_API_ID;

// if (tidioKey) {
//     const script = document.createElement('script');
//     script.src = `//code.tidio.co/${tidioKey}.js`;
//     script.async = true;
//     document.body.appendChild(script);
// }

message.config({
    top: 10,
    duration: 7,
    maxCount: 3,
    // rtl: true,
    // prefixCls: 'my-message',
});

defSetting()

ReactDOM.render(
    <Suspense fallback={
        <Result
            icon={<Loading3QuartersOutlined spin />}
            title="Please wait while we load the content!"
        // extra={<Button type="primary">Next</Button>}
        />}>
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
    </Suspense>
    , app);
    