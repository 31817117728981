import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Card, Typography,Button,Tooltip, Modal,  Row,Col,Space, Select, Switch, Slider, InputNumber} from 'antd';
import Table from '../_components/Table/Table';
import SecuritySelection from '../Market/SecurityMaster/_components/securitySelection';
import { DownloadFile, JSONTOCSV } from '../../Settings/commonFunctions';


const _state = {
  page: 1,
  totalCount: 0,
  pageSize: 10,
  data: [],
  filteredInfo: null,
  sortedInfo: null,
  search: '',
}
export default function FundHoldings() {
  const [loader, setLoader] = React.useState(false)
  const [CSVBtnloader, setCSVBtnloader] = React.useState(false)
  const [FundsData, setFundsData] = React.useState(_state)
  const [AUMMinValue, setAUMMinValue] = React.useState(null)
  const [AUMMaxValue, setAUMMaxValue] = React.useState(null)
  const [SelectedMinValue, setSelectedMinValue] = React.useState(null)
  const [SelectedMaxValue, setSelectedMaxValue] = React.useState(null)
  const [selectedID, setSelectedID] = React.useState()
  const [SelectedTickerName, setSelectedTickerName] = React.useState(null)
  const [cityList, setCityList] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [classificationList, setClassificationList] = useState([]);
  const [FiltersState, setFiltersState] = useState({});



  const GetMinMaxAUM = (SelectedTicker = { key: "security__id", value: null, child: null }) => {
    if (selectedID) {
      axios({
        method: 'get',
        url: `/securities/fundsDetails/Get_Min_Max_AUM/?security__id=${selectedID}`
      }).then(res => {
        if (res.data) {
          setAUMMaxValue(res.data['Max']);
          setAUMMinValue(res.data['Min'])
          if (!SelectedMaxValue && !SelectedMinValue) {
            setSelectedMaxValue(res.data['Max'])
            setSelectedMinValue(res.data['Min'])
          }
        }
      })
    }
  }

  const GetCityAndClassificationList = () => {
    if (selectedID) {
      axios({
        method: 'get',
        url: `/securities/fundsOwnership/get_securities_fund_cities/?security_id=${selectedID}`,
      }).then((res) => {
        if (res.data && res.data.cities) {
          setCityList(res.data.cities);
        }
        if (res.data && res.data.classification_type) {
          setClassificationList(res.data.classification_type);
        }
      });
    }
  };

  const RangeSlider = (
    <div
      className="custom-filter-dropdown ant-table-filter-dropdown"
      style={{ minWidth: "30rem", padding: "0.5rem 1rem" }}
    >
      <Row>
        <Col span={24} style={{ textAlign: 'center', justifyContent: 'center' }}>
          <Row>
            <Col span={24}>
              <Space>
                <strong>Min:</strong>
                <InputNumber style={{ width: 120 }} defaultValue={SelectedMinValue} value={SelectedMinValue} onChange={(v) => setSelectedMinValue(v)}></InputNumber>
                <strong>Max:</strong>
                <InputNumber style={{ width: 120 }} defaultValue={SelectedMaxValue} value={SelectedMaxValue} onChange={(v) => setSelectedMaxValue(v)}></InputNumber>
                <Button type="primary" onClick={() => { handleTableData() }}>Apply</Button>
                <Button type="primary" onClick={() => {
                  setSelectedMinValue(AUMMinValue);
                  setSelectedMaxValue(AUMMaxValue);
                }}
                >Reset</Button>
              </Space>
            </Col>
            <Col span={24}>
              <Slider range step={0.01} min={AUMMinValue} max={AUMMaxValue} defaultValue={[SelectedMinValue, SelectedMaxValue]} value={[SelectedMinValue, SelectedMaxValue]}
                onChange={(v) => {
                  setSelectedMaxValue(v[1])
                  setSelectedMinValue(v[0])
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

  let Column_list = [
    {
      title: 'Name',
      dataIndex: 'fund__name',
      sorter: true,
      className: 'unselectable',
      render: (row, record) => `${row}`
    },
    {
      title: 'City',
      dataIndex: 'fund__city',
      sorter: true,
      className: 'unselectable',
      filterMultiple:false,
      filters: cityList.map((city) => ({ text: city, value: city })),
      onFilter: (value, record) => record.fund__city.includes(value),
      render: (row, record) => `${record['fund__city']}, ${record['fund__state']}`,
      filterSearch: true,

    },
    { title: 'Phone Number ', dataIndex: 'fund__business_phone', sorter: false, className: 'unselectable', },
    {
      title: 'AUM (Bn)',
      dataIndex: 'fund__asset_under_management',
      sorter: true,
      className: 'unselectable',
      filter: [],
      filterDropdown: RangeSlider,
      render: (row) => row !== '-' && row !== '' && row !== null ? parseFloat(row).toFixed(2): '-'

    },
    {
      title: 'Classification',
      dataIndex: 'fund__classification__classification__type',
      sorter: false,
      className: 'unselectable',
      filterMultiple:false,
      filters: classificationList.map((classification) => ({ text: classification, value: classification })),
      onFilter: (value, record) => record.fund__classification__classification__type.includes(value),
      filterSearch: true,
    },
    { title: 'as_of_date', dataIndex: 'as_of_date', sorter: true, className: 'unselectable', },
    {
      title: 'Portfolio Weight',
      dataIndex: 'current_percent_of_portfolio',
      sorter: true,
      className: 'unselectable',
      render: (row) => row !== '-' && row !== '' && row !== null ? parseFloat(row).toFixed(4) + '%' : '-'
    },
    {
      title: 'Change Since Last Reporting',
      dataIndex: 'change_since_last_reporting',
      sorter: true,
      className: 'unselectable',
      render: (row) => row !== '-' && row !== '' && row !== null ? parseFloat(row).toFixed(4) + '%' : 0
    }
  
  ]

  const handlePageChange = (
    pagination = {
      current: FundsData.page,
      pageSize: FundsData.pageSize,
      total: FundsData.totalCount
    },
    filters,
    sorter
  ) => {
    setFiltersState(filters)
    handleTableData(FundsData.search, pagination, sorter, filters);
  }

  const [ csvURL,setcsvURL ] = useState('');
  const handleTableData = async (search = FundsData.search, pagination = {
    current: FundsData.page,
    pageSize: FundsData.pageSize,
    total: FundsData.totalCount
  }, sorter = FundsData.sortedInfo, filters = {}) => {
    const { current, pageSize } = pagination;
    let url = ""
    let CSVUrlTemp = ""
    setLoader(true);

    if (search !== undefined) {
      url += "&search=" + search
      CSVUrlTemp += "&search=" + search
    }
    if (SelectedMaxValue && SelectedMaxValue != AUMMaxValue){
      url += `&fund__asset_under_management__lte=${SelectedMaxValue}`;
      CSVUrlTemp += `&fund__asset_under_management__lte=${SelectedMaxValue}`;
    }  
    if (SelectedMinValue && SelectedMinValue != AUMMinValue){
      url += `&fund__asset_under_management__gte=${SelectedMinValue}`
      CSVUrlTemp += `&fund__asset_under_management__gte=${SelectedMinValue}`
    } 
    if (sorter && sorter.field){
      if(sorter.field == 'change_since_last_reporting'){
        url += `&ordering=${sorter.order === 'ascend' ? `${sorter.field}` : sorter.order === 'descend' ?   `-${sorter.field}` : "-current_percent_of_portfolio" }`
        CSVUrlTemp += `&ordering=${sorter.order === 'ascend' ? `${sorter.field}` : sorter.order === 'descend' ?   `-${sorter.field}` : "-current_percent_of_portfolio" }`
      }else{
        url += `&ordering=${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`;
        CSVUrlTemp += `&ordering=${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`;
      }
    } 
    else{
      url += '&ordering=-current_percent_of_portfolio';
      CSVUrlTemp += '&ordering=-current_percent_of_portfolio';
    } 

    let preUrl = `securities/fundsOwnership/?dataset_available=false&limit=${pageSize}&security__id=${selectedID}`
    url += "&offset=" + (pagination.current - 1) * pageSize
    
    let csvPreUrl = `securities/fundsOwnership/?dataset_available=false&limit=${pageSize > 50 ? pageSize : 50}&security__id=${selectedID}`

    if (filters.fund__city) {
      url += `&fund__city=${filters.fund__city}`;
      CSVUrlTemp += `&fund__city=${filters.fund__city}`;
    }
    if (filters.fund__classification__classification__type) {
      url += `&fund__classifications__classification__type=${filters.fund__classification__classification__type}`;
      CSVUrlTemp += `&fund__classifications__classification__type=${filters.fund__classification__classification__type}`;
    }

    if (selectedID) {
      setcsvURL(csvPreUrl + CSVUrlTemp  );
      axios.get(preUrl + url)
        .then((res) => {
          let dataResponse = {}
          dataResponse['data'] = res.data.results.map((row, i) => {
            return {
              "key": parseInt(i),
              "althub_fund_id": row?.fund?.id ? row.fund.id : '',
              "fund_id": row?.fund?.ww_fund_id ? row.fund.ww_fund_id : '',
              "fund__name": row?.fund?.name ? row.fund.name : '-',
              "fund__city": row?.fund?.city ? row.fund.city : '-',
              "fund__state": row?.fund?.state ? row.fund.state : '-',
              "fund__business_phone": row?.fund?.business_phone ? `+1 ${row.fund.business_phone.replace(".", " ").replace("-", " ")}` : '-',
              "as_of_date": row.as_of_date,
              "current_percent_of_portfolio": row?.current_percent_of_portfolio ? row.current_percent_of_portfolio : '-',
              "previous_percent_of_portfolio": row?.previous_percent_of_portfolio ? row.previous_percent_of_portfolio : '-',
              // "change_since_last_reporting":  (row?.current_percent_of_portfolio === '-' ? 0 : row?.current_percent_of_portfolio) - 
              // (row?.previous_percent_of_portfolio === '-' ? 0 : row?.previous_percent_of_portfolio), 
              "change_since_last_reporting": row?.change_since_last_reporting ? row.change_since_last_reporting : "-",
              "fund__classification__classification__type": row?.fund?.classification?.map(x => x.type).join(', '),
              "fund__crm_contacts": row.fund.crm_contacts,
              "fund__asset_under_management": row.fund.asset_under_management ? row.fund.asset_under_management : "-"
            }
          })
          dataResponse['page'] = pagination.current
          dataResponse['totalCount'] = res.data.count
          dataResponse['pageSize'] = FundsData.pageSize
          dataResponse['sortedInfo'] = sorter
          dataResponse['search'] = search

          setFundsData(dataResponse)
          let tickerNamesymbol = "";

          if (res?.data?.results?.length > 0) {
            const { security_name, symbol } = res.data.results[0];
            tickerNamesymbol = security_name ? `${security_name} (${symbol})` : "";
          }

          setSelectedTickerName(tickerNamesymbol);
        }).catch((err) => {
          console.log(err)
          setLoader(false)
        }).finally(() => {
          setLoader(false);
        })
    }
  }

  const handleSearch = (search,
    FiltersState,
    pagination = {
    current: FundsData.page,
    pageSize: FundsData.pageSize,
    total: FundsData.totalCount
  },
  sorter
) => {
    handleTableData(search, pagination, sorter, FiltersState);
  }

  const handleCsvDownload = (search = FundsData.search, pagination = {
    current: FundsData.page,
    pageSize: FundsData.pageSize,
    total: FundsData.totalCount
  }, sorter = FundsData.sortedInfo, filters = {}) => {


    const pageSize =  FundsData.pageSize > 50 ? FundsData.pageSize : 50 ;
    setCSVBtnloader(true);


    if (selectedID) {
      axios.get(csvURL)
        .then((res) => {
          let dataResponseCSV = {}
          dataResponseCSV['data'] = res.data.results.map((row, i) => {
            return {
              "key": parseInt(i),
              "althub_fund_id": row?.fund?.id ? row.fund.id : '',
              "fund_id": row?.fund?.ww_fund_id ? row.fund.ww_fund_id : '',
              "fund__name": row?.fund?.name ? row.fund.name : '-',
              "fund__city": row?.fund?.city ? row.fund.city : '-',
              "fund__state": row?.fund?.state ? row.fund.state : '-',
              "fund__business_phone": row?.fund?.business_phone ? `+1 ${row.fund.business_phone.replace(".", " ").replace("-", " ")}` : '-',
              "as_of_date": row.as_of_date,
              "current_percent_of_portfolio": row?.current_percent_of_portfolio ? row.current_percent_of_portfolio : '-',
              "change_since_last_reporting": row?.change_since_last_reporting ? row.change_since_last_reporting : "-",
              "fund__classification__classification__type": row?.fund?.classification?.map(x => x.type).join(', '),
              "fund__crm_contacts": row.fund.crm_contacts,
              "fund__asset_under_management": row.fund.asset_under_management ? row.fund.asset_under_management : "-"
            }
          })

          const newArray = dataResponseCSV.data.map(item => {
            const { althub_fund_id,fund__crm_contacts, fund_id, key, ...rest } = item;
            const modifiedRest = {};
            for (const key in rest) {
              if (Object.hasOwnProperty.call(rest, key)) {
                const modifiedKey = key.replace(/(?:^|_)(\w)/g, (_, letter) => letter.toUpperCase());
                modifiedRest[modifiedKey] = rest[key];
              }
            }
            return modifiedRest;
          });
          DownloadFile(JSONTOCSV(newArray), "Data");
          setCSVBtnloader(false);
        }).catch((err) => {
          console.log(err)
          setLoader(false)
        }).finally(() => {
          setLoader(false);
        })
    }
  }

  useEffect(() => {
    handleTableData();
    GetMinMaxAUM();
    GetCityAndClassificationList();
  }, [selectedID])

  useEffect(()=>{
  },[FundsData.data]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ marginBottom: '35px' }}>
          <Card className='Glass'
            style={{ height: '115px', paddingTop: '15px' }}
          >
            <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col span={18} >
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={10} lg={6} xl={6} xxl={6}>
                    <SecuritySelection setSelectedID={setSelectedID} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        {
          selectedID &&
          <>
            <Col span={24}>
              <Space>
                <div style={{ paddingLeft: '10px' }}>
                  {FundsData['data'].length > 0 && !loader && FundsData.totalCount !== 0 ? <Typography.Text style={{ fontSize: '14px', color: '#8D8D8D' }}>Displaying {FundsData.totalCount} funds that have invested in {SelectedTickerName}</Typography.Text> : ''}

                  {FundsData['data'].length == 0 && !loader ? <Typography.Text style={{ fontSize: '14px', color: '#8D8D8D' }}>We don't have holdings data </Typography.Text> : ''}

                </div>
              </Space>
            </Col>
            <Col span={24}>
              <Table
                propsCard={{
                  className: 'Glass',
                }}
                propsTable={{
                  fundsHolding: true,
                  loading: loader,
                  size: 'small',
                  columns: Column_list,
                  rowKey: "key",
                  data: FundsData.data,
                  CSVBtnloader: CSVBtnloader,
                  onChange: handlePageChange,
                  onSearching: (v)=>{handleSearch(v,FiltersState)},
                  handleCsvDownload:handleCsvDownload,
                  pagination: {
                    current: FundsData.page,
                    pageSize: FundsData.pageSize,
                    pageSizeOptions: [10, 25, 50, 100],
                    total: FundsData.totalCount,
                  }
                }}
              />
            </Col>
          </>
        }
      </Row>
    </>
  )
}