import React from 'react'
import axios from 'axios';
import moment from 'moment'
import WebSockets from './webSockets'
import { useNavigate } from 'react-router-dom'

import { Typography, Button, Comment, Tooltip, Avatar, Divider, message } from 'antd';

export default function Notification(props) {
    const login = JSON.parse(localStorage.getItem('login'))
    const Navigate = useNavigate();

    const [loading, setloading] = React.useState(false)
    const [Noti, setNoti] = React.useState([])
    const [Count, setCount] = React.useState(0)


    const getAllNotification = (offset) => {
        setloading(true)
        axios({
            method: 'get',
            url: `/notifications/?limit=10&offset=${offset}`,
        })
            .then(res => {
                if (res.data.count === 0) {
                    message.info("No More Notifications",2)
                    return
                }
                if (offset === 0) {
                    setNoti(res.data.results);
                    return
                }
                setNoti(noti => [...noti, ...res.data.results]);
            })
            .catch(err => {
            }).finally(() => setloading(false))
    }

    const callNext = e => {
        let count = Count + 10
        setCount(count)
        getAllNotification(count)
    }

    const handleClick = (data) => {
        if (!data.ref_id) return
        if (data.ref_id) {
            Navigate('/'+data.notification_type + "?ID=" + data.ref_id+"&PID="+login.user.client.id,{replace:true})
            props.setNotificationvisible(false)
        }
    }

    React.useEffect(() => {
        getAllNotification(0)
        return () => {
            // cleanup
        }
    }, [])

    return (


        <div
            id='user-menu-Notification'
            className={'user-action-notification '}
        >
            <div className='notifications'>
                {Noti.map(x => {
                    return (
                        <Comment
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClick(x)}
                            // actions={[]}
                            author={<a>{x.title}</a>}
                            avatar={<Avatar alt={x.title} >{x.notification_type[0]}</Avatar>}
                            content={x.description}
                            datetime={
                                <Tooltip title={moment(x.created).format('YYYY-MM-DD HH:mm:ss')}>
                                    <span>{moment(x.created).fromNow()}</span>
                                </Tooltip>
                            }
                        />
                    )
                })
                }
                <Divider>
                    <Button loading={loading} ghost onClick={callNext} type='link'>More</Button>
                </Divider>
                <WebSockets />
            </div>
        </div>
    )
}
