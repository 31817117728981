import axios from 'axios';
import { getCookie } from "./commonFunctions";

let BASE_URL = process.env.REACT_APP_SERVER_STRING;

axios.defaults.baseURL = BASE_URL;

let ActiveCalls = 0

export function defSetting() {
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        ActiveCalls = ActiveCalls + 1
        document.getElementById('page-loader') && document.getElementById('page-loader').classList.add('indeterminate')
        // document.body.classList.add('loading-indicator');
        return config;
    }, function (error) {
        ActiveCalls = ActiveCalls - 1
        document.getElementById('page-loader') && document.getElementById('page-loader').classList.remove('indeterminate')
        // Do something with request error
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger.
        // Do something with response data.
        ActiveCalls = ActiveCalls - 1
        if (ActiveCalls === 0) document.getElementById('page-loader') && document.getElementById('page-loader').classList.remove('indeterminate')
        // document.body.classList.remove('loading-indicator');
        return response;
    }, function (error) {
        ActiveCalls = 0
        document.getElementById('page-loader') && document.getElementById('page-loader').classList.remove('indeterminate')
        if (window.location.hostname !== "localhost") {
            if (error.response.status === 404) { window.location.href = '/404' }
            else if (error.response.status === 500) { window.location.href = '/500' }
            else if (error.response.status === 511) {
                localStorage.clear();
                window.location.href = './login'
                window.location.reload(true)
            }
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger.
        // Do something with response error.
        return Promise.reject(error);
    });
}

