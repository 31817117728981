import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BellOutlined, NotificationOutlined, UserOutlined, Icon} from '@ant-design/icons';

import { Layout, Menu, Breadcrumb, message, Divider, Drawer, Button, Avatar, Typography, Space } from 'antd';
import brandYellow from "../../../../assets/images/Invisage_Symbol_RGB_Yellow.png"
import AlthubPageLogo from "../../../../assets/images/AlthubPageLogo.png"
// import AlthubPageLogo from "../../../../assets/images/AlthubPageLogo_1.png"
import AltLogo from "../../../../assets/images/althubLogo.png"
import Profile from '../ProfileBar/Profile';
import Notification from '../Topbar/notification';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export default function Main(props) {
    const Location = useLocation();
    const [collapsed, setcollapsed] = useState(true)
    const [dataRoutes, setdataRoutes] = useState([]);
    const [selectedPage, setselectedPage] = useState(Location.pathname);
    const [Profilevisible, setProfilevisible] = React.useState(false)
    const [Notificationvisible, setNotificationvisible] = React.useState(false)
    const [details, setDetails] = React.useState({})

    const Navigate = useNavigate();

    const HandleClickProfile = (v) =>{
        if (v.key=="/Notifications"){setNotificationvisible(true);setProfilevisible(false)}
        if (v.key=="/Profile"){setProfilevisible(true);setNotificationvisible(false)}
    }
    const email = localStorage.getItem('email') 
    const password = localStorage.getItem('password')
    const getUserData = () =>{
        axios({
          method: 'GET',
          url: `/users/?id=${props.login.user.id}`,  
        }).then(res => {
          setDetails(res.data.results[0])
        }).catch(err => {
          message.error(err.response.data.data)
        }).finally(() => {
        })
      };
    
      React.useEffect(()=>{
        if (props?.login?.user?.id)getUserData()
      },[props])

    

    const onPathClick = x => {
        message.destroy()
        // window.scroll({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth',
        // })
        // setTimeout(() => {
        //     Navigate(x.key, { replace: true })
        // }, 300);
    }

    const generateRoutes = data => {
        let allowedPages = null
        if (props['login'].user.hasOwnProperty('allowed_pages')) allowedPages = props['login'].user.allowed_pages.map(x => x.toLowerCase())
        let rout = data.filter(z => allowedPages.indexOf(z.SSRKEY.toString().toLowerCase()) !== -1).map(x => x)
        return rout
    }

    React.useEffect(() => {
        setdataRoutes(pv => generateRoutes(props.routesList))
        setselectedPage(Location.pathname)
        return () => {
            // cleanup
        }
    }, [Location])

    const getItem = (label, key, icon, name,children, type) => {
        if (name == "Fundamental Edge"){
            key = 'Fundamental Edge'
        }
        return {
            key,
            icon,
            name,
            children,
            label,
            type,
        };
    }
    let total_items = dataRoutes.filter(z => z.invisible === false).map((x, key) =>  
    getItem(
        !['Notifications','Profile'].includes(x.name)?<Link to={x.path}>{x.name}</Link>:x.name, x.path, x.icon,x.name)
    )

    const items = total_items.filter(x=>!['My Portfolios','Notifications','Profile','My Datasets'].includes(x.name))

    const BottomItems = total_items.filter(x=>['My Portfolios','My Datasets'].includes(x.name))

    const UserItems = total_items.filter(x=>['Notifications','Profile'].includes(x.name))

    const handleMenuClick = (e) => {

        if (e.key === 'Fundamental Edge') {
            // window.open(`http://localhost:3001/autologinfromAlthub?email=${email}&password=${password}`, '_blank');
            window.open(`https://fe.althub.com/autologinfromAlthub?email=${email}&password=${password}`, '_blank');
        } else {
          onPathClick(e.key);
        }
      };

    return (<>
        <Sider
            // collapsible
            collapsed={collapsed}
            onCollapse={(e) => {
                setcollapsed(e)
            }}
            onMouseMove={()=>setcollapsed(false)}
            // onMouseHover={()=>setcollapsed(false)}
            onMouseMoveCapture={()=>setcollapsed(false)}
            onMouseEnter={()=>setcollapsed(false)}
            onMouseOut={()=>setcollapsed(true)}
            onMouseLeave={()=>setcollapsed(true)}
            style={{
                overflow: 'auto',
                height: "100vh",    
                }}
            >
                {collapsed&&
            <img className="navlogo" src={AlthubPageLogo} alt="logo" style={{ marginBottom: '1.4rem',height:'50px',width:'50px'}} />}
            {collapsed !=true &&
            <img className="navlogo" src={AltLogo} alt="logo" style={{width:'150px',height:'50px' ,marginBottom: '1.4rem' }} />
            }
            <Menu
                theme="dark"
                onClick={handleMenuClick}
                defaultSelectedKeys={[props.login.user.allowed_pages.includes("Rfolio")?"/rfolio":"/providers"]}
                selectedKeys={[selectedPage]}
                mode="inline"
                items={items}
            /> 
            {BottomItems.length>0&&
            <><Menu
                items={[{
                    type: 'divider',
                    style:{border:'1px solid rgba(128, 128, 128, 0.5)'}
                  }]}
            />
            <Menu 
                theme="dark"
                onClick={onPathClick}
                // defaultSelectedKeys={[props.login.user.allowed_pages.includes("Rfolio")?"/rfolio":"/provider"]}
                selectedKeys={[selectedPage]}
                mode="inline"
                items={BottomItems}
            >
                
            </Menu></>}
            <Menu
                items={[{
                    type: 'divider',
                    style:{border:'1px solid rgba(128, 128, 128, 0.5)'}
                  }]}
            />
            {/* <Divider /> */}
            <Menu 
                
                theme="dark"
                onClick={(v)=>{HandleClickProfile(v)}}
                // defaultSelectedKeys={[props.login.user.allowed_pages.includes("Rfolio")?"/rfolio":"/provider"]}
                selectedKeys={[selectedPage]}
                mode="inline"
                items={UserItems}
            >
                
            </Menu>
            
        </Sider>

        <Drawer
            destroyOnClose={true}
            id='for-profile'
            className='is-capitalized'
            title={"Howdy " + props.login?.user?.first_name}
            placement={"right"}
            closable
            onClose={() => setProfilevisible(false)}
            open={Profilevisible}
            key={"right-Profile"}
        >
            <Profile login={props.login} details={details} />
        </Drawer>

        <Drawer
            destroyOnClose={true}
            id='for-notification'
            className='is-capitalized'
            title={"Notifications"}
            placement={"right"}
            closable
            onClose={() => setNotificationvisible(false)}
            open={Notificationvisible}
            key={"right-Notifications"}
            >
                <Notification Open={Notificationvisible} setNotificationvisible={setNotificationvisible} />
        </Drawer>

        </>
    );
}
